import React, { createContext, useState, useEffect, useMemo } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });

  const [customColor, setCustomColor] = useState(() => {
    return localStorage.getItem("customColor") || "rwanda";
  });

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem("customColor", customColor);
    loadCustomCSS(customColor);
  }, [customColor]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const updateCustomColor = (color) => {
    setCustomColor(color);
  };

  const loadCustomCSS = (color) => {
    const existingLink = document.getElementById("custom-css");
    if (existingLink) {
      existingLink.remove();
    }

    const link = document.createElement("link");
    link.id = "custom-css";
    link.rel = "stylesheet";
    link.href = `/${color}index.css`;
    document.head.appendChild(link);
  };

  const contextValue = useMemo(
    () => ({
      theme,
      toggleTheme,
      customColor,
      updateCustomColor,
    }),
    [theme, customColor]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <div
        id={theme}
        className={theme === "dark" ? "dark-theme" : "light-theme"}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
