import React, { useEffect, useState } from "react";
import { Info, CaretDown, CaretUp } from "@phosphor-icons/react";
import paths from "@/utils/paths";
import System from "@/models/system";
import PreLoader from "@/components/Preloader";
import { LOCALAI_COMMON_URLS } from "@/utils/constants";
import useProviderEndpointAutoDiscovery from "@/hooks/useProviderEndpointAutoDiscovery";
import { useTranslation } from "react-i18next";

export default function LocalAiOptions({
  settings,
  showAlert = false,
  moduleSuffix = "",
}) {
  const { t } = useTranslation();
  const {
    autoDetecting: loading,
    basePath,
    basePathValue,
    showAdvancedControls,
    setShowAdvancedControls,
    handleAutoDetectClick,
  } = useProviderEndpointAutoDiscovery({
    provider: "localai",
    initialBasePath: settings?.LocalAiBasePath,
    ENDPOINTS: LOCALAI_COMMON_URLS,
  });
  const [apiKeyValue, setApiKeyValue] = useState(
    settings?.[`LocalAiApiKey${moduleSuffix}`]
  );
  const [apiKey, setApiKey] = useState(
    settings?.[`LocalAiApiKey${moduleSuffix}`]
  );

  return (
    <div className="w-full flex flex-col gap-y-4">
      {showAlert && (
        <div className="flex flex-col md:flex-row md:items-center gap-x-2 normal-text mb-6 bg-blue-800/30 w-fit rounded-lg px-4 py-2">
          <div className="gap-x-2 flex items-center">
            <Info size={12} className="hidden md:visible" />
            <p className="text-sm md:text-base">
              LocalAI as your LLM requires you to set an embedding service to
              use.
            </p>
          </div>
          <a
            href={paths.settings.embedder.modelPreference()}
            className="text-sm md:text-base my-2 underline"
          >
            Manage embedding &rarr;
          </a>
        </div>
      )}
      <div className="w-full flex items-center gap-4">
        {!settings?.[`credentialsOnly${moduleSuffix}`] && (
          <>
            <LocalAIModelSelection
              settings={settings}
              basePath={basePath.value}
              apiKey={apiKey}
              moduleSuffix={moduleSuffix}
            />
            <div className="flex flex-col w-60">
              <label className="normal-text text-sm font-semibold block mb-2">
                {t("localai.token-window")}
              </label>
              <input
                type="number"
                name={`LocalAiTokenLimit${moduleSuffix}`}
                className=" dark-input-mdl normal-text text-sm rounded-lg block w-full p-2"
                placeholder={t("localai.token-window-placeholder")}
                min={1}
                onScroll={(e) => e.target.blur()}
                defaultValue={settings?.[`LocalAiTokenLimit${moduleSuffix}`]}
                required={true}
                autoComplete="off"
              />
            </div>
          </>
        )}
        <div className="flex flex-col w-60">
          <div className="flex flex-col gap-y-1 mb-2">
            <label className="normal-text text-sm font-semibold flex items-center gap-x-2">
              {t("localai.api-key")}{" "}
              <p className="!text-xs !italic !font-thin">
                {t("localai.api-key-optional")}
              </p>
            </label>
          </div>
          <input
            type="password"
            name={`LocalAiApiKey${moduleSuffix}`}
            className=" dark-input-mdl normal-text text-sm rounded-lg block w-full p-2"
            placeholder={t("localai.api-key-placeholder")}
            defaultValue={
              settings?.[`LocalAiApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
            }
            autoComplete="off"
            spellCheck={false}
            onChange={(e) => setApiKeyValue(e.target.value)}
            onBlur={() => setApiKey(apiKeyValue)}
          />
        </div>
      </div>
      <div className="flex justify-start mt-4">
        <button
          onClick={(e) => {
            e.preventDefault();
            setShowAdvancedControls(!showAdvancedControls);
          }}
          className="text-white hover:text-white/70 flex items-center text-sm"
        >
          {showAdvancedControls
            ? t("localai.hide-advanced")
            : t("localai.show-advanced")}
          {showAdvancedControls ? (
            <CaretUp size={14} className="ml-1" />
          ) : (
            <CaretDown size={14} className="ml-1" />
          )}
        </button>
      </div>
      <div hidden={!showAdvancedControls}>
        <div className="w-full flex items-center gap-4">
          <div className="flex flex-col w-60">
            <div className="flex justify-between items-center mb-2">
              <label className="text-white text-sm font-semibold">
                {t("localai.base-url")}
              </label>
              {loading ? (
                <PreLoader size="6" />
              ) : (
                <>
                  {!basePathValue.value && (
                    <button
                      onClick={handleAutoDetectClick}
                      className="bg-primary-button text-xs font-medium px-2 py-1 rounded-lg hover:bg-secondary hover:text-white shadow-[0_4px_14px_rgba(0,0,0,0.25)]"
                    >
                      {t("localai.auto-detect")}
                    </button>
                  )}
                </>
              )}
            </div>
            <input
              type="url"
              name="LocalAiBasePath"
              className="bg-zinc-900 text-white placeholder:text-white/20 text-sm rounded-lg focus:outline-primary-button active:outline-primary-button outline-none block w-full p-2"
              placeholder={t("localai.base-url-placeholder")}
              value={basePathValue.value}
              required={true}
              autoComplete="off"
              spellCheck={false}
              onChange={basePath.onChange}
              onBlur={basePath.onBlur}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function LocalAIModelSelection({
  settings,
  basePath = null,
  apiKey = null,
  moduleSuffix = "",
}) {
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      if (!basePath || !basePath.includes("/v1")) {
        setCustomModels([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const { models } = await System.customModels(
        "localai",
        typeof apiKey === "boolean" ? null : apiKey,
        basePath
      );
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, [basePath, apiKey]);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-2">
          Chat Model Selection
        </label>
        <select
          name={`LocalAiModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2"
        >
          <option disabled={true} selected={true} className="normal-text">
            {basePath?.includes("/v1")
              ? "-- loading available models --"
              : "-- waiting for URL --"}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-2">
        Chat Model Selection
      </label>
      <select
        name={`LocalAiModelPref${moduleSuffix}`}
        required={true}
        className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-2"
      >
        {customModels.length > 0 && (
          <optgroup label="Your loaded models">
            {customModels.map((model) => {
              return (
                <option
                  className="normal-text"
                  key={model.id}
                  value={model.id}
                  selected={
                    settings?.[`LocalAiModelPref${moduleSuffix}`] === model.id
                  }
                >
                  {model.id}
                </option>
              );
            })}
          </optgroup>
        )}
      </select>
    </div>
  );
}
