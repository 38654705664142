import System from "@/models/system";
import { useEffect, useState } from "react";

export default function GeminiLLMOptions({ settings }) {
  const [inputValue, setInputValue] = useState(settings?.GeminiLLMApiKey);
  const [geminiApiKey, setGeminiApiKey] = useState(settings?.GeminiLLMApiKey);

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-80">
          <label className="normal-text text-sm font-semibold block mb-4">
            Google AI API Key
          </label>
          <input
            type="password"
            name="GeminiLLMApiKey"
            placeholder="Google Gemini API Key"
            defaultValue={settings?.GeminiLLMApiKey ? "*".repeat(20) : ""}
            required={true}
            autoComplete="off"
            spellCheck={false}
            onChange={(e) =>
              setInputValue(e.target.value.replace(/[\uF000-\uF8FF]/g, ""))
            }
            onBlur={() => setGeminiApiKey(inputValue)}
            className="dark-input-mdl modal-search-block normal-text  text-sm rounded-lg block w-full p-2.5"
          />
        </div>

        {!settings?.credentialsOnly && (
          <>
            <GeminiModelSelection apiKey={geminiApiKey} settings={settings} />
            <div className="flex flex-col w-60">
              <label className="normal-text text-sm font-semibold block mb-4">
                Safety Setting
              </label>
              <select
                name="GeminiSafetySetting"
                defaultValue={settings?.GeminiSafetySetting || "BLOCK_NONE"}
                required={true}
                className="dark-input-mdl modal-search-block normal-text  text-sm rounded-lg block w-full p-2.5"
              >
                <option value="BLOCK_NONE">None (default)</option>
                <option value="BLOCK_ONLY_HIGH">Block few</option>
                <option value="BLOCK_MEDIUM_AND_ABOVE">Block some</option>
                <option value="BLOCK_LOW_AND_ABOVE">Block most</option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function GeminiModelSelection({ apiKey, settings }) {
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("gemini", apiKey);
      if (models?.length > 0) {
        const uniqueModels = [];
        const seenIds = new Set();
        const seenNames = new Set();

        models.forEach((model) => {
          const modelId = model.id;
          const displayName = model.name;

          if (!seenIds.has(modelId) && !seenNames.has(displayName)) {
            seenIds.add(modelId);
            seenNames.add(displayName);
            uniqueModels.push(model);
          }
        });

        const modelsByOrganization = uniqueModels.reduce((acc, model) => {
          acc[model.experimental ? "Experimental" : "Stable"] =
            acc[model.experimental ? "Experimental" : "Stable"] || [];
          acc[model.experimental ? "Experimental" : "Stable"].push(model);
          return acc;
        }, {});
        setGroupedModels(modelsByOrganization);
      }
      setLoading(false);
    }
    findCustomModels();
  }, [apiKey]);
  if (loading) {
    return (
      <div className="flex flex-col w-40">
        <label className="normal-text text-sm font-semibold block mb-4">
          Chat Model Selection
        </label>
        <select
          name="GeminiLLMModelPref"
          disabled={true}
          className="dark-input-mdl modal-search-block normal-text text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true}>
            -- loading available models --
          </option>
        </select>
      </div>
    );
  }
  return (
    <div className="flex flex-col w-70">
      <label className="text-normal text-sm font-semibold block mb-3">
        Chat Model Selection
      </label>
      <select
        name="GeminiLLMModelPref"
        required={true}
        className="dark-input-mdl modal-search-block normal-text text-sm rounded-lg block w-full p-2.5"
      >
        {Object.keys(groupedModels)
          .sort((a, b) => {
            if (a === "Stable") return -1;
            if (b === "Stable") return 1;
            return a.localeCompare(b);
          })
          .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option
                  key={model.id}
                  value={model.id}
                  selected={settings?.GeminiLLMModelPref === model.id}
                >
                  {model.id}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
}
