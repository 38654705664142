import { useTranslation } from "react-i18next";

export default function TextGenWebUIOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  return (
    <div className="flex gap-4 flex-wrap">
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          {t("textgenwebui.base-url")}
        </label>
        <input
          type="url"
          name={`TextGenWebUIBasePath${moduleSuffix}`}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          placeholder={t("textgenwebui.base-url-placeholder")}
          defaultValue={settings?.[`TextGenWebUIBasePath${moduleSuffix}`]}
          required={true}
          autoComplete="off"
          spellCheck={false}
        />
      </div>
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          {t("textgenwebui.token-window")}
        </label>
        <input
          type="number"
          name={`TextGenWebUITokenLimit${moduleSuffix}`}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          placeholder={t("textgenwebui.token-window-placeholder")}
          min={1}
          onScroll={(e) => e.target.blur()}
          defaultValue={settings?.[`TextGenWebUITokenLimit${moduleSuffix}`]}
          required={true}
          autoComplete="off"
        />
      </div>
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          {t("textgenwebui.api-key")}
        </label>
        <input
          type="password"
          name={`TextGenWebUIAPIKey${moduleSuffix}`}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          placeholder={t("textgenwebui.api-key-placeholder")}
          defaultValue={
            settings?.[`TextGenWebUIAPIKey${moduleSuffix}`]
              ? "*".repeat(20)
              : ""
          }
          autoComplete="off"
          spellCheck={false}
        />
      </div>
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          {t("textgenwebui.max-tokens")}
        </label>
        <input
          type="number"
          name={`TextGenWebUIMaxTokens${moduleSuffix}`}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          placeholder={t("textgenwebui.max-tokens-placeholder")}
          min={1}
          defaultValue={
            settings?.[`TextGenWebUIMaxTokens${moduleSuffix}`] || 1024
          }
          required={true}
          autoComplete="off"
        />
      </div>
    </div>
  );
}
