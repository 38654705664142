import { useTranslation } from "react-i18next";

export default function AzureAiOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            {t("azure.service-endpoint")}
          </label>
          <input
            type="url"
            name={`AzureOpenAiEndpoint${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2 focus:outline-none"
            placeholder={t("azure.service-endpoint-placeholder")}
            defaultValue={settings?.[`AzureOpenAiEndpoint${moduleSuffix}`]}
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            {t("azure.api-key")}
          </label>
          <input
            type="password"
            name={`AzureOpenAiKey${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2 focus:outline-none"
            placeholder={t("azure.api-key-placeholder")}
            defaultValue={
              settings?.[`AzureOpenAiKey${moduleSuffix}`] ? "*".repeat(20) : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            {t("azure.chat-deployment")}
          </label>
          <input
            type="text"
            name={`AzureOpenAiModelPref${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2 focus:outline-none"
            placeholder={t("azure.chat-deployment-placeholder")}
            defaultValue={settings?.[`AzureOpenAiModelPref${moduleSuffix}`]}
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>
      </div>

      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            {t("azure.token-limit")}
          </label>
          <select
            name={`AzureOpenAiTokenLimit${moduleSuffix}`}
            defaultValue={
              settings?.[`AzureOpenAiTokenLimit${moduleSuffix}`] || 4096
            }
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2 focus:outline-none"
            required={true}
          >
            <option value={4096}>4,096 (gpt-3.5-turbo)</option>
            <option value={16384}>16,384 (gpt-3.5-16k)</option>
            <option value={8192}>8,192 (gpt-4)</option>
            <option value={32768}>32,768 (gpt-4-32k)</option>
            <option value={128000}>
              128,000 (gpt-4-turbo,gpt-4o,gpt-4o-mini)
            </option>
          </select>
        </div>

        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            {t("generic.embedding-deployment")}
          </label>
          <input
            type="text"
            name={`AzureOpenAiEmbeddingModelPref${moduleSuffix}`}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2 focus:outline-none"
            placeholder={t("generic.embedding-deployment-placeholder")}
            defaultValue={
              settings?.[`AzureOpenAiEmbeddingModelPref${moduleSuffix}`]
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
          />
        </div>
        <div className="flex-flex-col w-60"></div>
      </div>
    </div>
  );
}
