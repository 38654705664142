import React, { useContext, useEffect } from "react";
import { OnboardingLayout } from "./Steps";
import { useParams } from "react-router-dom";
import Home from "./Steps/Home";
import LLMPreference from "./Steps/LLMPreference";
import UserSetup from "./Steps/UserSetup";
import DataHandling from "./Steps/DataHandling";
import CreateWorkspace from "./Steps/CreateWorkspace";
import { ThemeContext } from "@/context/index";

const OnboardingSteps = {
  home: Home,
  "llm-preference": LLMPreference,
  "user-setup": UserSetup,
  "data-handling": DataHandling,
  "create-workspace": CreateWorkspace,
};

export default function OnboardingFlow() {
  const { step } = useParams();
  const StepPage = OnboardingSteps[step || "home"];

  const { updateCustomColor } = useContext(ThemeContext);

  useEffect(() => {
    updateCustomColor("foynet");
  }, [updateCustomColor]);

  if (step === "home" || !step) return <StepPage />;

  return (
    <OnboardingLayout>
      {(setHeader, setBackBtn, setForwardBtn) => (
        <StepPage
          setHeader={setHeader}
          setBackBtn={setBackBtn}
          setForwardBtn={setForwardBtn}
        />
      )}
    </OnboardingLayout>
  );
}
