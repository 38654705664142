const TRANSLATIONS = {
  common: {
    "workspaces-name": "Izina ry'umwanya w'akazi",
    error: "ikosa",
    success: "ibyatunganye",
    user: "Ukoresha",
    selection: "Guhitamo modeli",
    saving: "Kubika...",
    save: "Bika impinduka",
    previous: "Paji ibanziriza",
    next: "Paji ikurikira",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Urabyizeye ko ushaka gusiba {{name}}?\nNyuma y'ibi, ntabwo izaboneka muri iyi porogaramu.\n\nIbi ntibishobora guhindurwa.",
  deleteConfirmation:
    "Urabyizeye ko ushaka gusiba {{username}}?\nNyuma y'ibi, bazasohoka muri konti yabo kandi ntibazashobora kongera gukoresha iyi porogaramu.\n\nIbi ntibishobora guhindurwa.",
  suspendConfirmation:
    "Urabyizeye ko ushaka guhagarika {{username}}?\nNyuma y'ibi, bazasohoka muri konti yabo kandi ntibazashobora kongera kwinjira muri iyi porogaramu kugeza igihe umuyobozi azaba yongeye kubemerera.",
  flushVectorCachesWorkspaceConfirmation:
    "Ese uri wemeza ko ushaka gusiba ububiko bw'igihe gito bwa vekiteri kuri iyi mbuga y'akazi?",

  // Setting Sidebar menu items.
  settings: {
    title: "Igenamiterere rya Instance",
    system: "Igenamiterere rusange",
    invites: "Ubutumire",
    users: "Abakoresha",
    workspaces: "Ahantu ho gukorera",
    "workspace-chats": "Ibiganiro by'ahantu ho gukorera",
    customization: "Guhindura",
    "api-keys": "API y'Abakora",
    llm: "LLM",
    transcription: "Gushyira mu nyandiko",
    embedder: "Inkoranyabitabo",
    "text-splitting": "Gusiga no Gukata Ijambo",
    "vector-database": "Dabase y'Urutonde",
    embeds: "Gushyira mu Kiganiro",
    "embed-chats": "Inyandiko z'Ibiganiro",
    security: "Umutekano",
    "event-logs": "Ibyanditswe by'Ibikorwa",
    privacy: "Ubwiru & Amakuru",
    "ai-providers": "Abatanga AI",
    "agent-skills": "Ubushobozi bw'Umukozi",
    admin: "Ubuyobozi",
    tools: "Ibikoresho",
    audio: "Impamvu z'Ijwi",
    "link-settings": "Igenamiterere",
    "default-settings": "Igenamiterere Rya Default",
    "browser-extension": "Browser Extension",
    "prompt-upgrade-llm": "LLM yo kunoza ibibazo",
    "voice-speech": "Ijwi & Kuvuga",
    "pdr-settings": "Igenamiterere rya PDR",
  },

  binary_llm_selection: {
    title: "Hitamo LLM",
    description: "Hitamo LLM ushaka gukoresha kuri iyi gikorwa",
    local: "Aho hantu",
    remote: "Kure",
    "document-builder": "Ubwubatsi bw'inyandiko",
  },

  // Qura buttons,
  qura: {
    "copy-to-cora": "Genzura inkomoko hamwe na Qura",
    "qura-status": "Akabuto ka Qura ni ",
    "copy-option": "Gukoporora",
    "option-quest": "Ikibazo",
    "option-resp": "Igisubizo",
    "role-description":
      "Ongeraho buto ya Qura kugirango uhite usubiza kuri Qura.law",
  },

  thread_name_error:
    "Izina ry'urukurikirane rigomba kuba nibura inyuguti 3 kandi rikaba rigizwe gusa n'inyuguti, imibare, intera, cyangwa imirongo.",

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Murakaza neza kuri",
      "placeholder-username": "Izina ry'Umukoresha",
      "placeholder-password": "Ijambo ry'Ibanga",
      login: "Injira",
      validating: "Kwemeza...",
      "forgot-pass": "Wibagiwe ijambo ry'ibanga",
      reset: "Subiramo",
    },
    "sign-in": {
      start: "Injira muri konti yawe",
      end: "account.",
    },
    button: "injira",
    password: {
      forgot: "WIBAGIWE IJAMBO RY'IBANGA?",
      contact: "Nyamuneka hamagara umuyobozi wa sisitemu.",
    },
    publicMode: "Uburyo rusange",
  },

  "new-workspace": {
    title: "Uburyo bushya bwo gukora",
    placeholder: "Uburyo bwanjye bwo gukora",
    "legal-areas": "Inzego z'Amategeko",
    create: {
      title: "Shiraho ahantu hashya ho gukorera",
      description:
        "Nyuma yo gushyiraho aka gahunda, abazoberezi gusa ni bo bazakabona. Ushobora kongeraho abakoresha nyuma yo gushyiraho.",
      error: "Ikosa : ",
      cancel: "Kumva",
      "create-workspace": "Shiraho ahantu ho gukorera",
    },
  },

  "workspace-chats": {
    welcome: "Murakaza neza mu buryo bushya bwo gukora.",
    "desc-start": "Kugira ngo utangire, niyo",
    "desc-mid": "ushyira inyandiko",
    "desc-or": "cyangwa",
    start: "Kugira ngo utangire",
    "desc-end": "ohereza ubutumwa.",
    "attached-file": "Dosiye yometse",
    "attached-files": "Dosiye zometse",
    prompt: {
      send: "Ohereza",
      "send-message": "Ohereza ubutumwa",
      placeholder: "Saba amakuru y'ubujyanama bw'amategeko",
      slash: "Reba amabwiriza yose aboneka yo kuganira.",
      "change-size": "Hindura ubunini bw'inyandiko",
      reset: "/gusubiramo",
      clear: "Siba amateka yawe y'ibiganiro kandi utangire ikiganiro gishya",
      "new-preset": "Ongeraho Preset nshya",
      command: "Itegeko",
      description: "Ibisobanuro",
      save: "bika",
      small: "Ntoya",
      normal: "Isanzwe",
      large: "Nini",
      attach: "Fata dosiye uyishyire muri iki kiganiro",
      upgrade: "kuzamura page yawe",
      upgrading: "Kuvugurura ubutumwa bwawe",
      "original-prompt": "Icyifuzo cya mbere:",
      "upgraded-prompt": "Icyifuzo cyatejwe imbere:",
      "edit-prompt":
        "Ushobora guhindura icyo ushyizemo gishya mbere yo kugitanga",
      "speak-prompt": "Vuga prompt yawe",
      "view-agents": "Reba abakozi bose bashobora gukoresha mu kuganira",
      "ability-tag": "Ubushobozi",
    },
  },

  header: {
    account: "Konti",
    login: "Kwinjira",
    "sign-out": "Gusohoka",
  },

  workspace: {
    title: "Ibikorwa byo kuri Instance",
    description:
      "Ibi ni ibikorwa byose biba kuri iyi instance. Gukuraho ibikorwa bizavanaho ibiganiro byose n'imitunganyirize yabyo.",
    "new-workspace": "Gikorwa Gishya",
    name: "Izina",
    link: "Uhuza",
    users: "Abakoresha",
    type: "Ubwoko",
    "created-on": "Yakozwe Ku",
    save: "Bika impinduka",
    cancel: "Hagarika",
    deleted: {
      title: "Ahantu ho gukora ntabwo habonetse!",
      description:
        "Bisa nk'aho ahantu ho gukora hifashishwa iri zina ritaboneka.",
      homepage: "Garuka ku rupapuro rw'itangiriro",
    },
    "no-workspace": {
      title: "Nta mwanya w'akazi uhari",
      description: "Ntabwo ufite uburenganzira ku myanya y'akazi.",
      "contact-admin":
        "Nyamuneka vugana n'umuyobozi wawe kugira ngo usabe uburenganzira.",
      "learn-more": "Menya byinshi ku myanya y'akazi",
    },
  },

  contextual: {
    checkbox: {
      label: "Guhuza n'ibindi",
      hint: "Koresha uburyo bwo guhuza n'ibindi kugira ngo wongerere imikorere y'ibindi bintu",
    },
    systemPrompt: {
      label: "Ibibazo bya Sisitemu",
      placeholder: "Andika igisubizo...",
      description:
        "Urugero: Nyabuneka, tanga ibisobanuro bigufi kugira ngo ushyire iyi gice mu nyandiko yose kugira ngo bikoroshye gushaka igice. Subiza gusa n'ibisobanuro bigufi ntakindi.",
    },
    userPrompt: {
      label: "Ibibazo by'Ukoresha",
      placeholder: "Andika igisubizo...",
      description:
        "Urugero: <document>\n{file}\n</document>\nNgiki igice turashaka gushyira mu nyandiko yose\n<chunk>\n{chunk}\n</chunk>",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "Igenamiterere Rusange",
    chat: "Igenamiterere rya Chat",
    vector: "Ibigega bya Vector",
    members: "Abanyamuryango",
    agent: "Igenamiterere rya Agent",
    "general-settings": {
      "workspace-name": "Izina ry'Icyumba Cy'Akazi",
      "desc-name": "Ibi bizahindura gusa izina ryerekanwe ry'icyumba cy'akazi.",
      "assistant-profile": "Ishusho y'umwirondoro w'Umufasha",
      "assistant-image":
        "Hindura ishusho y'umwirondoro w'umufasha w'icyumba cy'akazi.",
      "workspace-image": "Ishusho y'Icyumba Cy'Akazi",
      "remove-image": "Kuraho Ishusho y'Icyumba Cy'Akazi",
      delete: "Siba Icyumba Cy'Akazi",
      deleting: "Gusiba Icyumba Cy'Akazi...",
      update: "Hindura ahakorerwa",
      updating: "Birimo guhindura ahakorerwa...",
    },
    "chat-settings": {
      type: "Ubwoko bwa Chat",
      private: "Ihererekanyabubasha",
      standard: "Ibisanzwe",
      "private-desc-start": "izaha uburenganzira bwo kwinjira",
      "private-desc-mid": "bonyine",
      "private-desc-end": "abantu bashyizweho gusa.",
      "standard-desc-start": "izaha uburenganzira bwo kwinjira",
      "standard-desc-mid": "abantu bose",
      "standard-desc-end": "bashya.",
    },
    users: {
      manage: "Gucunga Abakoresha",
      "workspace-member": "Nta banyamuryango b'icyumba cy'akazi",
      username: "Izina ry'umukoresha",
      role: "Uruhare",
      date: "Itariki Yongeweho",
      users: "Abakoresha",
      search: "Shakisha umukozi",
      "no-user": "Nta mukoresha wabonetse",
      select: "Hitamo Byose",
      unselect: "Kuraho Guhitamo",
      save: "Bika",
    },
    "linked-workspaces": {
      title: "Ahantu Habigenewe",
      "linked-workspace": "Nta hantu habigenewe",
      manage: "Shyiramo Ahantu Habigenewe",
      name: "Izina",
      slug: "Izina ry'abigenewe",
      date: "Itariki Yongeweho",
      workspaces: "Ahantu Habigenewe",
      search: "Shakisha ahantu habigenewe",
      "no-workspace": "Nta hantu habigenewe habonetse",
      select: "Hitamo Byose",
      unselect: "Kuraho",
      save: "Bika",
      description:
        "Niba ahantu ho gukora hafatanyije, amakuru y'amategeko ajyanye n'icyo usaba azaboneka mu buryo bwihuse kuva muri buri karere k'amategeko hafatanyije. Menya ko ahantu ho gukora hafatanyije bizatuma igihe cyo gutunganya kirekire",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Umubare w'Urutonde",
      description: "Umubare wose w'urutonde muri vektordatabas yawe.",
      vectors: "Umubare wa vektori",
    },
    names: {
      description:
        "Ibi bizahindura gusa izina ryerekanwe ry'uburyo bwo gukora bwawe.",
    },
    message: {
      title: "Ubutumwa busabwe mu kiganiro",
      description:
        "Hindura ubutumwa buzajya butangwa ku bakoresha uburyo bwawe bwo gukora.",
      add: "Ongeraho ubutumwa bushya",
      save: "Bika ubutumwa",
      heading: "Sobanurira",
      body: "ibyiza by'uru rubuga",
      message: "Ubutumwa",
      "new-heading": "Ingingo nyamukuru",
    },
    pfp: {
      title: "Ishusho y'Umufasha",
      description: "Hindura ishusho y'umufasha w'uburyo bwo gukora.",
      image: "Ishusho y'uburyo bwo gukora",
      remove: "Kuramo ishusho y'uburyo bwo gukora",
    },
    delete: {
      delete: "Siba uburyo bwo gukora",
      deleting: "Gusiba uburyo bwo gukora...",
      "confirm-start": "Uri hafi yo gusiba uburyo bwawe bwose bwa",
      "confirm-end":
        "uburyo bwo gukora. Ibi bizakuraho byose muri vektordatabas yawe.\n\nIbindi byangombwa by'ubwoko bizaguma bitariho. Iyi bikorwa ntishobora guhindurwa.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Umugabuzi wa LLM w'uburyo bwo gukora",
      description:
        "Umugabuzi n'umusaruro wa LLM uzakoreshwa kuri ubu buryo bwo gukora. By default, bikoresha umugabuzi wa LLM w'uburyo bwa sisitemu n'ibyifuzo.",
      search: "Shakisha abagabuzi bose ba LLM",
    },
    model: {
      title: "Modeli y'ibiganiro y'uburyo bwo gukora",
      description:
        "Umusaruro w'ibiganiro uzakoreshwa kuri ubu buryo bwo gukora. Niba nta kintu, bizakoresha ibyifuzo bya LLM by'uburyo.",
      wait: "-- tegereza ibimenyetso --",
    },
    mode: {
      title: "Imikorere y'ibiganiro",
      chat: {
        title: "Ikiganiro",
        "desc-start": "izatanga ibisubizo n'ubumenyi bwa LLM busanzwe",
        and: "kandi",
        "desc-end": "ibibazo byabonetse.",
      },
      query: {
        title: "Gusaba",
        "desc-start": "izatanga ibisubizo",
        only: "byonyine",
        "desc-end": "niba ibibazo byabonetse.",
      },
    },
    history: {
      title: "Amateka y'ibiganiro",
      "desc-start":
        "Umubare w'ibiganiro byabanjirije bizashyirwa mu byibutsa by'igihe gito by'uburyo bw'ibisubizo.",
      recommend: "Tega 20. ",
      "desc-end":
        "Ibintu birenze 45 birashoboka cyane kuba byatera ibibazo mu kiganiro kubera ubunini bw'ubutumwa.",
    },
    prompt: {
      title: "Ijambo rikoresha",
      description:
        "Ijambo rizakoreshwa kuri ubu buryo bwo gukora. Sobanura ibyifuzo n'amabwiriza ku AI kugira ngo itange igisubizo. Ugomba gutanga ijambo ryakozwe neza kugira ngo AI itange igisubizo gihamye kandi gisobanutse.",
    },
    refusal: {
      title: "Igisubizo cya refusal muri mode ya query",
      "desc-start": "Muri mode ya",
      query: "query",
      "desc-end":
        "ugomba kugarura igisubizo cya refusal igihe nta context yabonetse.",
    },
    temperature: {
      title: "Ubushyuhe bwa LLM",
      "desc-start":
        'Iyi igenamiterere iragena uburyo "kreatif" ibisubizo bya LLM bizaba bimeze.',
      "desc-end":
        "Umubare uri hejuru, ubusumbirizaho. Ku bimenyetso bimwe, ibi birashobora guteza ibisubizo bitari bigufi mugihe byashyizwe hejuru cyane.",
      hint: "Iyi LLM zifite ingero zinyuranye z'ingero zifite agaciro. Shakisha umugabuzi wa LLM.",
    },
    max_tokens: {
      title: "Umubare ntarengwa w'ibimenyetso ku mukoresha",
      desc: "Shiraho umubare ntarengwa w'ibimenyetso byemewe byo kwemeza umwirondoro umukoresha ashobora kugira rimwe. Iyo birenze, ibimenyetso bishaje bizakurwa byikora.",
      label: "Umubare ntarengwa w'ibimenyetso",
      help: "Agaciro kagomba kuba karenze zeru",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Indangantabire ya Vector",
    snippets: {
      title: "Ibisobanuro bya Maximum Context Snippets",
      description:
        "Iyi igenamiterere iragenzura umubare w'ibyibutsa by'ibibazo byoherejwe kuri LLM ku kiganiro cyangwa gusaba.",
      recommend: "Recommandé: 4",
    },
    doc: {
      title: "Uruhare rwa Document Similarity",
      description:
        "Umubare w'ibyibutsa bisabwa kugira ngo ubutumwa bufate ibyibutsa ku kibazo. Uruhare runini, igice kinini cya response gifite.",
      zero: "Nta cyitonderwa",
      low: "Gukura (score de similarité ≥ .25)",
      medium: "Medi (score de similarité ≥ .50)",
      high: "Hejuru (score de similarité ≥ .75)",
    },
    reset: {
      reset: "Guhindura Vektordatabas",
      resetting: "Gusiba vectors...",
      confirm:
        "Uri hafi yo guhindura Vektordatabas y'uburyo bwo gukora. Ibi bizakuraho vectors byose byinjijwe.\n\nIbindi byangombwa by'ubwoko bizaguma bitariho. Iyi bikorwa ntishobora gusubizwamo.",
      error: "Vektordatabas y'uburyo bwo gukora ntiyashobora gusubizwamo!",
      success: "Vektordatabas y'uburyo bwo gukora yasubizwemo!",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "Imikorere y'ibiganiro LLM itarafashijwe neza ni ubuhanga n'uburyo. Ubuhanga buto bushobora kuba butari bwiza cyangwa budakora.",
    provider: {
      title: "Umugabuzi wa LLM w'uburyo bwo gukora",
      description:
        "Umugabuzi n'umusaruro wa LLM uzakoreshwa kuri ubu buryo bwo gukora @agent.",
    },
    mode: {
      chat: {
        title: "Modeli y'ibiganiro by'umukozi w'uburyo bwo gukora",
        description:
          "Modeli y'ibiganiro izakoreshwa kuri ubu buryo bwo gukora @agent.",
      },
      title: "Modeli y'umukozi w'uburyo bwo gukora",
      description:
        "Modeli y'ibiganiro izakoreshwa kuri ubu buryo bwo gukora @agent.",
      wait: "-- tegereza ibimenyetso --",
    },

    skill: {
      title: "Ubuhanga bw'umukozi busanzwe",
      description:
        "Itezimbere ubushobozi busanzwe bw'umukozi usanzwe hamwe n'ubu buhanga bwubatswe. Iyi iteganyijwe ikoreshwa ku buryo bwose bwo gukora.",
      rag: {
        title: "RAG & Kwibuka igihe kirekire",
        description:
          "Reka umukozi akoreshe ibinyabuzima byawe byo kugusubiza cyangwa usabe umukozi 'kwibuka' ibice by'ibintu byo kwibuka igihe kirekire.",
      },
      configure: {
        title: "Gushyiraho Ubumenyi bw'Umukozi",
        description:
          "Hindura no kongera ubushobozi bw'umukozi w'ibanze w'ibanze ukoresheje kwemeza cyangwa kwima ubumenyi bwihariye. Ibi bigenamiterere bizakoreshwa mu byumba byose by'akazi.",
      },
      view: {
        title: "Reba & Subiramo inyandiko",
        description:
          "Reka umukozi agaragaze no gusubiramo ibintu byo muri workspace byinjiye.",
      },
      scrape: {
        title: "Gusoroma ibikubiyemo ku mbuga",
        description: "Reka umukozi asure no gusoroma ibikubiyemo ku mbuga.",
      },
      generate: {
        title: "Gukora grafike",
        description:
          "Tegereza umukozi usanzwe gukora grafike itandukanye y'ibibazo bitangwa cyangwa byatanzwe mu kiganiro.",
      },
      save: {
        title: "Gukora & Kubika dosiye mu murandasi",
        description:
          "Tegereza umukozi usanzwe gukora no kwandika dosiye zibika kandi zishobora gutegurwa mu murandasi.",
      },
      web: {
        title: "Gushakisha & Kwerekeza ku mbuga z'amakuru",
        "desc-start":
          "Tegereza umukozi wawe gushakisha ku mbuga z'amakuru ku bibazo byawe byibwira n'ubushobozi bwa web-surfing (SERP).",
        "desc-end":
          "Gushakisha ku mbuga z'amakuru mu kiganiro cy'umukozi ntibizakora igihe kitazashyirwaho.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Ibiganiro by'Umwanya w'Akazi",
    description:
      "Ibi ni ibiganiro byose n'ubutumwa byanditswe byoherejwe n'abakoresha, bitondekanyijwe hakurikijwe itariki byakoreweho.",
    export: "Kohereza",
    table: {
      id: "Id",
      by: "Byoherejwe na",
      workspace: "Umwanya w'Akazi",
      prompt: "Prompt",
      response: "Igisubizo",
      at: "Byoherejwe",
      invoice: "Ref. y'inyemezabuguzi",
      "completion-token": "Ikimenyetso cyo Gusoza",
      "prompt-token": "Ikimenyetso cyo Gusaba",
    },
    "clear-chats": "Gusiba ibiganiro",
    "confirm-clear-chats":
      "Uzi neza ko ushaka gusiba ibiganiro byose?\n\nIyi gikorwa ntishobora gusubizwa inyuma.",
    "fine-tune-modal": "Gusaba Fine-Tune model",
    "confirm-delete.chat":
      "Uzi neza ko ushaka gusiba iki kiganiro?\n\nIyi gikorwa ntishobora gusubizwa inyuma.",
    next: "Paji ikurikira",
    previous: "Paji ibanza",
    filters: {
      "by-name": "Sukura hakurikijwe izina ry'umukoresha",
      "by-reference": "Numero y'icyerekezo",
    },
  },

  // Appearance
  appearance: {
    title: "Kugaragara",
    description: "Hindura uburyo bwo kugaragara bw'urubuga rwawe.",
    logo: {
      title: "Hindura Logo",
      description: "Shyira logo yawe kugirango wihindurire no kumenyekanisha.",
      add: "Ongeraho logo yawe",
      recommended: "Ingano itangwa: 800 x 200",
      remove: "Kuramo",
    },
    logoDark: {
      title: "Hindura logo yo kuri mode y'ijoro",
      description: "Shyiraho logo yihariye yo kuri mode y'ijoro.",
    },
    message: {
      title: "Hindura ubutumwa",
      description: "Hindura ubutumwa bwikora bwerekanwa kubakoresha bawe.",
      new: "Bushya",
      system: "sisitemu",
      user: "umukoresha",
      message: "ubutumwa",
      assistant: "Chat Assistant",
      "double-click": "Kanda kabiri kugira ngo uhindure...",
      save: "Bika ubutumwa",
    },
    icons: {
      title: "Ikirangantego cy'inyuma",
      description: "Hindura ikirangantego cy'inyuma kiri hasi y'inyuma.",
      icon: "Ikirangantego",
      link: "Inyandiko",
    },
    display: {
      title: "Ururimi rwo Kwerekana",
      description: "Hitamo ururimi rwo kwerekana.",
    },
    color: {
      title: "Amasaro Yihariye",
      "desc-start": "Hindura",
      "desc-mid": "*ibara ry'inyuma, *ibara ry'ibanze",
      "desc-and": "kandi",
      "desc-end": "*ibara ry'inyandiko",
      red: "Umutuku",
      gray: "Ikigina",
      foynet: "Foynet",
      brown: "Ikigina cy'umukara",
      green: "Icyatsi",
      yellow: "Umuhondo",
      cyan: "Ubururu bwera",
      magenta: "Magenta",
      orange: "TenderFlow",
      purple: "Umuhengeri",
      navy: "Ubururu bwijimye",
      black: "Umukara",
    },
    login: {
      title: "Inyandiko yihariye yo kwinjira",
      description: "Hindura inyandiko igaragara ku ipaji yo kwinjira.",
      placeholder: "Nyamuneka hamagara umuyobozi wa sisitemu",
      website: {
        title: "Guhindura urubuga rwa porogaramu",
        description: "Hindura URL y'urubuga rwa porogaramu yawe.",
        toggle: "Erekana isano y'urubuga",
      },
      validation: {
        invalidURL: "Nyamuneka injiza URL yemewe.",
      },
    },
    siteSettings: {
      title: "Amategeko yihariye ya sitasiyo",
      description:
        "Hindura ibikubiye mu karita ka mudasobwa kugirango wihindurire no kumenyekanisha.",
      tabTitle: "Umutwe wa karita",
      tabDescription:
        "Shyiramo umutwe wihariye wa karita iyo porogaramu ifunguwe muri mudasobwa.",
      tabIcon: "Ikiranga karita",
      fabIconUrl: "Shyiraho URL y'ishusho yo gukoresha nk'ikiranga cya karita.",
      placeholder: "url y'ifoto yawe",
    },
    appName: {
      title: "Izina ryihariye rya porogaramu",
      description:
        "Shyiraho izina ryihariye rya porogaramu rigaragara ku ipaji yo kwinjira.",
    },
    customTab: {
      title: "Hindura izina rya tab",
      tab1: "Tab 1",
      tab2: "Tab 2",
    },
  },

  // API Keys
  api: {
    title: "Imfunguzo za API",
    description:
      "Imfunguzo za API zemerera umukoresha kwinjira no gucunga iyi instance mu buryo bwa program.",
    link: "Soma API Documentation",
    generate: "Kora imfunguzo nshya za API",
    table: {
      key: "Imfunguzo za API",
      by: "Byakozwe na",
      created: "Byakozwe",
    },
    new: {
      title: "Shiraho urufunguzo rushya rwa API",
      description:
        "Nyuma yo gushyiraho, urufunguzo rwa API rushobora gukoreshwa kugira ngo ubone no gukonfigure iyi instance ya mu buryo bwa porogaramu.",
      doc: "Soma inyandiko za API",
      cancel: "Kumva",
      "create-api": "Shiraho urufunguzo rwa API",
    },
  },

  llm: {
    title: "Inzira ya LLM",
    description:
      "Ibi ni amakuru ajyanye na serivisi y'ibanze yo kuganira no gukoresha LLM wahisemo. Ni ingenzi ko aya makuru y'ingenzi ari aya vuba kandi nyayo, ubundi uburyo ntibuzakora neza.",
    provider: "LLM Provider",
    "none-selected": "Nta na kimwe",
    "select-llm": "Ugomba guhitamo LLM",
    "search-llm": "Shakisha abatangabukorwa ba LLM bose",
    "context-window-warning":
      "Iburira: Ntabwo byashobotse kubona idirishya ry'inyandiko ku cyitegererezo cyatoranijwe.",
    "prompt-upgrade": {
      title: "Utanga serivisi ya LLM yo kunoza ibibazo",
      description:
        "Utanga serivisi ya LLM n'uburyo bikoreshwa mu kunoza ibibazo by'ukoresha. Mu buryo busanzwe, ikoresha serivisi ya sisitemu ya LLM n'igenamiterere yayo.",
      search: "Shakisha abatanga serivisi ba LLM baboneka kuri iyi gice",
    },
  },

  "llm-provider": {
    openai: "Icyitegererezo gisanzwe kubikorwa bidasanzwe by'ubucuruzi.",
    azure:
      "Icyitegererezo cy'ubucuruzi cya OpenAI gihagarariwe na Azure services.",
    anthropic: "Umufasha w'AI w'inshuti wahagarariwe na Anthropic.",
    gemini: "Modèle y'AI nini cyane kandi ifite ubushobozi bwa Google",
    huggingface:
      "Gerageza 150 000+ LLM ifite umutekano n'umuryango wa AI ku isi",
    ollama: "Koresha LLM zigendanwa ku mashini yawe.",
    lmstudio:
      "Gerageza, ikura, no gukoresha imbaga y'abantu ikora LLM imirimo isanzwe y'ibikorwa.",
    localai: "Koresha LLM zigendanwa ku mashini yawe.",
    togetherai: "Koresha imbonerahamwe za Together AI.",
    mistral: "Koresha imbonerahamwe za Mistral AI.",
    perplexityai:
      "Koresha imbonerahamwe zifite ingufu kandi zihujwe n'interineti zashyizweho na Perplexity AI.",
    openrouter: "Imiterere y'inyenyeri ya LLM.",
    groq: "Ibisubizo byihuse bya LLM birangwa na real-time AI applications.",
    koboldcpp: "Koresha LLM zigendanwa ukoresheje koboldcpp.",
    oobabooga:
      "Koresha LLM zigendanwa ukoresheje interface ya Oobabooga Text Generation Web.",
    cohere: "Koresha imbonerahamwe za Cohere Command.",
    lite: "Koresha proxy ya LiteLLM ifite uburyo bwiza bwa OpenAI ku LLM nyinshi.",
    "generic-openai":
      "Shyira mu buryo bwiza na service y'ubucuruzi ya OpenAi biciye mu igenamiterere ry'ubucuruzi",
    native: "Koresha Llama Model y'ubucuruzi y'iyi instance.",
  },

  // audio preference
  audio: {
    title: "Ihitamo rya Kuvuga-ubwanditsi",
    provider: "Umukoresha",
    "desc-speech":
      "Hano ushobora gutangaza ubwoko bwa serivisi za kuvuga-ubwanditsi n'izivuga-kwandika ushaka gukoresha muri platformu yawe. Ku by default, dukoresha ubufasha bw'inzibacyuho bw'umurongo w'ishusho ariko ushobora gukoresha izindi.",
    "title-text": "Ihitamo rya Kuvuga-ubwanditsi",
    "desc-text":
      "Hano ushobora gutangaza ubwoko bwa serivisi za kuvuga-ubwanditsi ushaka gukoresha mu buryo bwa platformu. Ku by default, dukoresha ubufasha bw'inzibacyuho bw'umurongo w'ishusho ariko ushobora gukoresha izindi.",
    "desc-config": "Nta bikorwa byo gushyiraho bigenewe uyu mukoresha.",
    "placeholder-stt": "Shaka abakoresha kuvuga-ubwanditsi",
    "placeholder-tts": "Shaka abakoresha ubwanditsi-kwandika",
    "native-stt":
      "Koresha serivisi ya STT y'umurongo w'ishusho niba ikemerewe.",
    "native-tts":
      "Koresha serivisi ya TTS y'umurongo w'ishusho niba ikemerewe.",
    openai: "Koresha amajwi ya OpenAI ya ubwanditsi-kwandika.",
    elevenlabs:
      "Koresha amajwi n'ikoranabuhanga rya ElevenLabs rya ubwanditsi-kwandika.",
  },

  transcription: {
    title: "Inzira y'Icyitegererezo cya Transcription",
    description:
      "Izi ni ibintu n'igenamiterere yawe ya icyitegererezo cya transcription provider. Birakenewe ko izi mfunguzo ziriho kandi zikwiriye.",
    provider: "Transcription Provider",
    "warn-start":
      "Gukoresha icyitegererezo cya whisper kuri mashini ifite RAM cyangwa CPU bicye cyane bishobora guhagarika urubuga igihe cyo gutegura dosiye za media.",
    "warn-recommend": "Turagusaba byibura 2GB ya RAM kandi dosiye yawe <10Mb.",
    "warn-end":
      "Icyitegererezo cya inbuilt kizahita gikururwa igihe cyo gukoresha bwa mbere.",
    "search-audio": "Shakisha abagabuzi ba transcription audio",
  },

  embedding: {
    title: "Inzira y'Inkoranyabitabo",
    "desc-start":
      "Mugihe ukoresheje LLM idafite inkoranyabitabo yihariye - urashobora kugira uruhare rwiyongereye rw'inkoranyabitabo.",
    "desc-end":
      "Inkoranyabitabo ni uburyo bwo guhindura inyandiko mu rutonde. Izi nkeneye kugira uruhare mu kubyara dosiye na prompts mu ikarito ya urubuga.",
    provider: {
      title: "Inkoranyabitabo",
      description:
        "Nta igenamiterere rikeneye igihe ukoresheje instance inbuilt embedding engine.",
      "search-embed": "Shakisha abagabuzi bose ba embedding",
    },
    workspace: {
      title: "Imyitozo y'Ihuriro rya Workspace",
      description:
        "Uwatanga hamwe n'imyitozo runaka bizakoreshwa muri iri huriro. By'ibanze, ikoreshwa na provider n'imiterere ya system.",
      "multi-model":
        "Inkunga ya multi-model ntabwo irashyigikirwa kuri uyu provider.",
      "workspace-use": "Iri huriro rizakoresha",
      "model-set": "imiterere ya model yagenwe kuri system.",
      embedding: "Model ya Workspace Embedding",
      model:
        "Model runaka y'imyitozo izakoreshwa muri iri huriro. Niba ituzuye, izakoresha imyitozo ya system.",
      wait: "-- dutegereje models --",
      setup: "Gushiraho",
      use: "Gukoresha",
      "need-setup":
        "nk'umukozi w'imyitozo muri iri huriro, ugomba kubanza kuyishyiraho.",
      cancel: "Guhagarika",
      save: "Bika",
      settings: "Igenamiterere",
      search: "Shakisha abatanga imyitozo bose",
      "need-llm":
        "nk'umukozi w'LLM muri iri huriro, ugomba kubanza kuyishyiraho.",
    },
  },

  text: {
    title: "Text deling & Chunking Preferences",
    "desc-start":
      "Igihe kinini, urashobora guhindura uburyo bwo gushyiramo no kubika mbere yo gushyiramo vektordatabas.",
    "desc-end":
      "Ugomba guhindura iyi igenamiterere gusa niba usobanukiwe uburyo bwo gusiga no kubika inyandiko kandi n'ingaruka zayo.",
    "warn-start": "Inyandiko ziri hano zizaba gusa kuri",
    "warn-center": "inyandiko nshya z'ububiko",
    "warn-end": ", ntabwo inyandiko zihari.",
    size: {
      title: "Ingano ya Text Chunk",
      description:
        "Iyi ni uburebure bwa karactere zishobora kuboneka muri vektordatabas imwe.",
      recommend: "Uburebure bwa model y'ububiko ni",
    },

    overlap: {
      title: "Ubwiyongere bwa Text Chunk",
      description:
        "Iyi ni uburebure bwa karactere ziba ziriho mugihe cyo gusiga hagati ya text chunk ebyiri ziri.",
    },
  },

  // Vector Database
  vector: {
    title: "Vektordatabas",
    description:
      "Izi ni ibintu n'igenamiterere yawe ya instance izakora. Birakenewe ko izi mfunguzo ziriho kandi zikwiriye.",
    provider: {
      title: "Vektordatabas",
      description: "Nta igenamiterere rikeneye kuri LanceDB.",
      "search-db": "Shakisha abagabuzi bose ba vektordatabas",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Ibikoresho by'Ikiganiro",
    description:
      "Ibikoresho by'ikiganiro bishobora guhurizwa hamwe ni interface za chat zisanzwe zifite urutonde rw'uburyo bwakozwe. Ibi bifasha kubaka ubuhanga bwa chat bishobora gutegurwa ku isi yose.",
    create: "Kora embedding",
    table: {
      workspace: "Uburyo bwo gukora",
      chats: "Ibiganiro byatanzwe",
      Active: "Domains ziriho",
    },
  },

  "embed-chats": {
    title: "Ibiganiro by'Ikiganiro",
    export: "Export",
    description:
      "Aha ni ibiganiro byose byanditswe n'abakoresha bitumye bashyizweho.",
    table: {
      embed: "Gushyira mu buryo bwo gukora",
      sender: "Ohereza",
      message: "Ubutumwa",
      response: "Igisubizo",
      at: "Byoherejwe kuri",
    },
  },

  multi: {
    title: "Imikorere y'Abantu Benshi",
    description:
      "Tegura instance yawe gushyigikira itsinda ryawe ukoresheje Multi-User Mode.",
    enable: {
      "is-enable": "Imikorere y'Abantu Benshi yashyizweho",
      enable: "Shyira imikorere y'Abantu Benshi",
      description:
        "By default, uzaba umuyobozi wenyine. Nk'umuyobozi ugomba gukora accounts y'abantu bose bashya cyangwa abashinzwe. Ntakibazo cyawe kijyanye n'ijambo ry'ibanga kubera ko umuyobozi wenyine ashobora gusubiramo ijambo ry'ibanga.",
      username: "Izina ry'umuyobozi w'uburyo bwo gukora",
      password: "Ijambo ry'ibanga rya account y'umuyobozi",
    },
    password: {
      title: "Kurinda Ijambo ry'ibanga",
      description:
        "Kurinda instance ya na password. Niba ushaka irindi jambo ry'ibanga, nta buryo bwo kongera gukosora, nyamuneka ubike ijambo ry'ibanga.",
    },
    instance: {
      title: "Kurinda ijambo ry'ibanga instance",
      description:
        "By default, uzaba umuyobozi wenyine. Nk'umuyobozi ugomba gukora accounts y'abantu bose bashya cyangwa abakozi. Ntakibazo cyawe kijyanye n'ijambo ry'ibanga kubera ko umuyobozi wenyine ashobora gusubiramo ijambo ry'ibanga.",
      password: "Ijambo ry'ibanga rya instance",
    },
  },

  // Event Logs
  event: {
    title: "Ibyanditswe by'Ibikorwa",
    description:
      "Reba ibikorwa byose n'ibikorwa bibera kuri instance iriho kugira ngo ugenzure.",
    clear: "Siba ibyanditswe by'Ibikorwa",
    table: {
      type: "Ubwoko bw'Ibikorwa",
      user: "Umukoresha",
      occurred: "Byabaye kuri",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Ubwiru & Gukora ku Makuru",
    description:
      "Iyi ni configuration yawe ku bijyanye n'uburyo abatanga service ziriho na urubuga rwacu bakora ku makuru yawe.",
    llm: "Guhitamo LLM",
    embedding: "Inzira y'Inkoranyabitabo",
    vector: "Vektordatabas",
    anonymous: "Telemetry y'Ubwiru yashyizweho",
    "desc-event": "Ibikorwa byose ntibizandika IP-adresse kandi ntibikubiyemo",
    "desc-id": "gushushanya",
    "desc-cont":
      "by'amakuru, ibyifuzo, ibiganiro, cyangwa andi makuru atariyo. Reba urutonde rw'ibyanditswe by'ibikorwa ushobora kureba kuri",
    "desc-git": "Github hano",
    "desc-end":
      "Nka projet ya open-source, twubaha uburenganzira bwawe bwo kugumana ubwiru. Twiyemeje gukora umuti mwiza wo gushyira hamwe AI n'inyandiko mu bwiru no mu mutekano. Niba ushaka kubika telemetry, turagusaba gusaba kwitabira gutanga ibitekerezo n'ibitekerezo kugira ngo dukomeze kunoza platform kubw'inyungu yawe",
  },
  "default-chat": {
    welcome: "Murakaza neza kuri IST Legal.",
    "choose-legal": "Hitamo ahantu h'ubujyanama bw'amategeko ibumoso.",
  },
  invites: {
    title: "Ubutumire",
    description:
      "Kora links z'ubutumire ku bantu mu rwego rw'umuryango wawe kugira ngo bemere kandi biyandikishe. Ubutumire burashobora gukoreshwa n'umuntu umwe gusa.",
    link: "Kora link y'ubutumire",
    accept: "Kwemezwa na",
    "created-by": "Byakozwe na",
    created: "Byakozwe",
    new: {
      title: "Kurema ubutumire bushya",
      "desc-start":
        "Nyuma yo kurema uzashobora gukoporora ubutumire no kubwohereza ku mukoresha mushya aho bashobora gukora konti nka",
      "desc-mid": "defaults",
      "desc-end":
        "rimwe kandi bikazajya byongerwa ku masoko y'akazi yatoranijwe.",
      "auto-add": "Kwongerera umukoresha kubyakozwe",
      "desc-add":
        "Urashobora guhitamo kongera umukoresha kubyakozwe munsi mu gutoranya. Defaults, umukoresha ntazaba afite amasoko y'akazi agaragara. Urashobora gutoranya amasoko y'akazi nyuma yo kwakira ubutumire.",
      cancel: "Guhagarika",
      "create-invite": "Gukora ubutumire",
      error: "Ikosa : ",
    },
    "link-copied": "Link y'ubutumire yakopowe",
    "copy-link": "Koperora link y'ubutumire",
    "delete-invite-confirmation":
      "Wizeye ko ushaka gusiba ubu butumire?\nNyuma y'ibi ntibuzongera gukoreshwa.\n\nIyi action ntishobora gusubizwa inyuma.",
    status: {
      label: "Imiterere",
      pending: "Bitegerejwe",
      disabled: "Byafunzwe",
      claimed: "Byemewe",
    },
  },
  "user-menu": {
    edit: "Hindura Konti",
    profile: "Ishusho y' umwirondoro",
    size: "800 x 800",
    "remove-profile": "Kuramo Ishusho y' umwirondoro",
    username: "Izina ry'umukoresha",
    "username-placeholder": "Izina ry'umukoresha",
    "new-password": "Ijambo ry'ibanga rishya",
    "new-password-placeholder": "ijambo ry'ibanga rishya",
    cancel: "Reka",
    update: "Hindura Konti",
    language: "Ururimi rukunda",
    email: "Aderesi imeri",
    "email-placeholder": "Andika aderesi imeri",
  },

  "user-setting": {
    description:
      "Aha niho buri konte iri kuri ubu buryo ibarizwa. Gusiba konte bizahita bisiba uburenganzira bwabo kuri iyi sisitemu.",
    "add-user": "Ongeraho ukoresha",
    username: "Izina rya konti",
    role: "Uruhare",
    default: "Bisanzwe",
    manager: "Umuyobozi",
    admin: "Umucungamakuru",
    "date-added": "Itariki yongeweho",
    "all-domains": "Domeni zose",
    "other-users": "Abandi bakoresha (Nta domeni)",
    edit: "Hindura: ",
    "new-password": "Ijambo ry'ibanga rishya",
    "password-rule": "Ijambo ry'ibanga rigomba kuba rifite byibura inyuguti 8.",
    "update-user": "Vugurura ukoresha",
    placeholder: "Izina ry'ukoresha",
    cancel: "Hagarika",
    "remove-user": "Siba ukoresha",
    "remove-user-title": "Siba ukoresha",
    "remove-user-confirmation": "Uzi neza ko ushaka gusiba uyu mukoresha?",
    error: "Ikosa: ",
  },

  sidebar: {
    thread: {
      "load-thread": "Gukora threads....",
      "starting-thread": "Gutanga Thread...",
      thread: "page nshya",
      delete: "Siba byatoranyijwe",
      rename: "Hindura izina",
      "delete-thread": "Siba Thread",
      deleted: "yasibwe",
      default: "page ibanza",
      "empty-thread": "page",
      "rename-message": "Ushaka kwita iri zina iki urukurikirane?",
      "delete-message":
        "Urizeye ko ushaka gusiba uru rukurikirane? Ibiganiro byose birimo bizasibwa. Nta bwo ushobora gusubiza inyuma.",
    },
  },

  embeder: {
    allm: "Koresha inkoranyabitabo ya platform. Nta igenamiterere rikeneye!",
    openai: "Icyitegererezo gisanzwe kubikorwa bidasanzwe by'ubucuruzi.",
    azure:
      "Icyitegererezo cy'ubucuruzi cya OpenAI gihagarariwe na Azure services.",
    localai: "Koresha inkoranyabitabo zigendanwa ku mashini yawe.",
    ollama: "Koresha inkoranyabitabo zigendanwa ku mashini yawe.",
    lmstudio:
      "Gerageza, ikura, no gukoresha imbaga y'abantu ikora LLM imirimo isanzwe y'ibikorwa.",
    cohere: "Koresha inkoranyabitabo zifite ubushobozi bwa Cohere.",
    voyageai: "Koresha inkoranyabitabo zifite ubushobozi bwa Voyage AI.",
  },

  vectordb: {
    lancedb:
      "Database ya vector 100% ikorera kuri ubu buryo hamwe na platform.",
    chroma:
      "Database ya vector ifunguye ushyira kuri server yawe cyangwa ku rubuga rwa cloud.",
    pinecone:
      "Database ya vector 100% ikorera ku rubuga rwa cloud ku bigo binini.",
    zilliz:
      "Database ya vector ihujwe n'ijwi rya cloud, yagenewe ibigo binini ifite kubahiriza SOC 2.",
    qdrant:
      "Database ya vector ifunguye, ikorera ahantu hamwe no ku rubuga rwa cloud.",
    weaviate:
      "Database ya vector ifunguye, ikorera ahantu hamwe no ku rubuga rwa cloud, ifite imikorere myinshi.",
    milvus:
      "Ifunguye, ifite ubushobozi bwo gukura cyane, kandi ikora vuba cyane.",
    astra: "Ubushakashatsi bwa vector ku GenAI mu isi nyayo.",
  },

  system: {
    title: "Ibyifuzo by'uburyo",
    "desc-start":
      "Ibi ni ibyo gushyiraho no guhindura uburyo bwose bw'ibanze bwawe.",
    user: "Abakoresha bashobora gusiba ibigo by'imirimo",
    "desc-delete":
      "Emeza abakoresha batari admin gusiba ibigo by'imirimo barimo. Ibi byasiba ikigo cy'imirimo kuri bose.",
    limit: {
      title: "Kugena umubare w'ubutumwa bwa buri mukoresha ku munsi",
      "desc-limit":
        "Kugena umubare w'ibibazo cyangwa ibiganiro byemewe mu masaha 24 ku bakoresha batari abayobozi. Koresha ibi kugira ngo wirinde abakoresha kwongera ikiguzi cya OpenAI.",
      "per-day": "Umubare ntarengwa w'ubutumwa ku munsi",
      label: "Kugena ubutumwa: ",
    },
    state: {
      enabled: "Byemewe",
      disabled: "Byafunzwe",
    },
    "source-highlighting": {
      title: "Kwemerera / Kudakoresha gushyira ahagaragara inkomoko",
      description:
        "Hisha cyangwa erekana gushyira ahagaragara inkomoko ku bakoresha.",
      label: "Gushyiraho inkomoko: ",
    },
    "usage-registration": {
      title: "Gushyira mu bikorwa inyandiko z'ibiciro",
      description:
        "Kora cyangwa uhagarike gushyira mu bikorwa inyandiko z'ibiciro mu igenzura ry'uburyo.",
      label: "Gushyira mu bikorwa inyandiko z'ibiciro ni ",
    },
    "forced-invoice-logging": {
      title: "Gushyira mu bikorwa inyandiko z'ibiciro z'ibiciro",
      description:
        "Kora kugirango usabe icyerekezo cy'ibiciro mbere yo gukoresha urubuga.",
      label: "Gushyira mu bikorwa inyandiko z'ibiciro ni ",
    },
    save: "Bika impinduka",
  },

  support: {
    title: "Imeri y'Ubwunganizi",
    description:
      "Shyiraho aderesi y'imeli y'ubwunganizi igaragara mu menyu y'umukoresha mu gihe winjiye muri iyi nshuro.",
    clear: "Sukura",
    save: "Bika",
  },
  "public-mode": {
    enable: "Shyiraho Uburyo bwa Umukoresha-Mubuyobozi",
    enabled: "Uburyo bwa Umukoresha-Mubuyobozi Bwarashizweho",
  },
  button: {
    delete: "Gusiba",
    edit: "Hindura",
    suspend: "Hagarika",
    unsuspend: "Gusubukura",
    accept: "Kwemera",
    decline: "Kwirengagiza",
    save: "Bika",
    "flush-vector-caches": "Siba ububiko bw'igihe gito bwa vekiteri",
    saving: "Kubika",
  },
  "new-user": {
    title: "Ongeraho umukozi ku ishusho",
    username: "Izina ry'umukoresha",
    "username-ph": "Izina ry'umukoresha",
    password: "Ijambo ry'ibanga",
    "password-ph": "ijambo ry'ibanga ry'umukoresha",
    "invalid-username":
      "Izina ry'ukoresha rigomba kuba rigizwe n'inyuguti ntoya, imibare, udukubo (_), utudomo (.), @ n'udukoni (-) gusa",
    role: "Umwanya",
    default: "Default",
    manager: "Umuyobozi",
    admin: "Umuyobozi mukuru",
    description:
      "Nyuma yo gushyiraho umukozi, azakenera kwinjira akoresheje ijambo ry'ibanga rye rya mbere kugira ngo abone uburenganzira.",
    cancel: "Kumva",
    "add-User": "Ongeraho umukozi",
    error: "Ikosa : ",
    "invalid-email": "Nyamuneka, shyiramo aderesi imeri yemewe.",
    permissions: {
      title: "Uburenganzira",
      default: [
        "Ashobora kohereza chats gusa mu bice by'akazi yashyizwemo n'umuyobozi cyangwa abashinzwe.",
        "Ntashobora guhindura imiterere iyo ari yo yose.",
      ],
      manager: [
        "Ashobora kureba, gukora, no gusiba bice by'akazi byose no guhindura imiterere y'ibyo bice by'akazi.",
        "Ashobora gukora, kuvugurura no gutumira abakoresha bashya muri iri koranabuhanga.",
        "Ntashobora guhindura LLM, vectorDB, kwinjiza, cyangwa izindi nshamake.",
      ],
      admin: [
        "Ububasha bwo hejuru bw'umukoresha.",
        "Ashobora kureba no gukora byose ku buryo bwose.",
      ],
    },
  },
  "new-embed": {
    title: "Shiraho embed nshya kuri workspace",
    error: "Ikosa: ",
    "desc-start":
      "Nyuma yo gushyiraho embed, uzahabwa umuhora ushobora gushyira ku rubuga rwawe hamwe na",
    script: "script",
    tag: "tag.",
    cancel: "Kumva",
    "create-embed": "Shiraho embed",
    workspace: "Workspace",
    "desc-workspace":
      "Iyi ni workspace chat window yawe izashingiraho. Defaults zose zizaba zaragijwe na workspace keretse ibi bishobora kuba byahinduwe na configuration iyi.",
    "allowed-chat": "Uburyo bwa chat bwemewe",
    "desc-query":
      "Shyiraho uko chatbot yawe ikwiye gukora. Query bivuze ko izasubiza gusa niba hari inyandiko ifasha gusubiza ikibazo.",
    "desc-chat":
      "Chat ifungura chat ku bibazo byose byihariye kandi ishobora gusubiza ibibazo bitari bijyanye na workspace yawe.",
    "desc-response": "Chat: Gusubiza ibibazo byose ntacyo bititaye ku konteksi",
    "query-response":
      "Query: Gusubiza gusa ibibazo bijyanye n'inyandiko muri workspace",
    restrict: "Funga requests zituruka ku masite",
    filter:
      "Uyu muyoboro uzabuza requests zose zituruka ku masite atari ari kuri lisiti iri hasi.",
    "use-embed":
      "Gusiga iki kibanza gisa bisobanuye ko umuntu wese ashobora gukoresha embed yawe ku rubuga urwo ari rwo rwose.",
    "max-chats": "Chats max ku munsi",
    "limit-chats":
      "Shyiraho umubare wa chats iyi embeded chat ishobora kwakira mu gihe cy'amasaha 24. Zero ni ukuvuga ko ntarengwa.",
    "chats-session": "Chats max ku mwanya",
    "limit-chats-session":
      "Shyiraho umubare wa chats umukoresha w'umwanya ashobora kohereza na iyi embed mu gihe cy'amasaha 24. Zero ni ukuvuga ko ntarengwa.",
    "enable-dynamic": "Shyiraho imikoreshereze ya dynamic model",
    "llm-override":
      "Emerera gushyiraho model ya LLM wifuza kugira ngo isimbuze iy'ahantu hasanzwe.",
    "llm-temp": "Shyiraho ubushyuhe bwa dynamic LLM",
    "desc-temp":
      "Emerera gushyiraho ubushyuhe bwa LLM kugira ngo busimbuze iy'ahantu hasanzwe.",
    "prompt-override": "Shyiraho igisimbura Prompt",
    "desc-override":
      "Emerera gushyiraho prompt ya system kugira ngo isimbuze iy'ahantu hasanzwe.",
  },

  "show-toast": {
    "recovery-codes": "Kodi zo gusubiza byakopwe mu bubiko",
    "scraping-website":
      "Gukura amakuru ku rubuga - ibi bishobora gufata akanya.",
    "fetching-transcript": "Gukura inyandiko ya videwo ya YouTube.",
    "updating-workspace": "Kuvugurura umwanya w'akazi...",
    "flashing-started": "Gutangira flashing...",
    "flashing-success": "Flashing yarangiye neza",
    "flashing-error": "Habaye ikosa muri flashing: {{error}}",
    "link-uploaded": "Umuhora washyizweho neza",
    "password-reset": "Ijambo ry'ibanga ryasubiwemo neza",
    "invalid-reset": "Ikimenyetso cyo gusubiramo kitari cyiza",
    "delete-option": "Thread ntiyashoboye gusibwa!",
    "thread-deleted": "Thread yakuweho neza!",
    "chat-deleted": "Ikiganiro cyasibwe neza!",
    "failed-delete-chat": "Byanze gusiba ikiganiro. Ongera ugerageze.",
    "error-deleting-chat": "Habaye ikosa mugihe cyo gusiba ikiganiro.",
    "chat-memory-reset":
      "Ububiko bw'ikiganiro cy'umwanya w'akazi bwasubijwe inyuma!",
    "picture-uploaded": "Ifoto y' umwirondoro yashyizweho.",
    "profile-updated": "Imyirondoro yashyizwe mu buryo bushya.",
    "logs-cleared": "Ibyo wabonye byabitswe neza.",
    "preferences-updated":
      "Preferences za system zashyizwe mu buryo bushya neza.",
    "user-created": "Umukoresha yashyizweho neza.",
    "user-creation-error": "Ntibyakunze gukora umutekano w'umukoresha: ",
    "user-deleted": "Umukoresha yakuweho mu system.",
    "workspaces-saved": "Workspaces zabitswe neza!",
    "failed-workspaces": "Byanze kubika workspaces. Ongera ugerageze.",
    "api-deleted": "Urufunguzo rwa API rwahanaguwe burundu",
    "api-copied": "Urufunguzo rwa API rwashyizwe mu kibanza cy'ibyo wandika",
    "appname-updated": "Izina rya application ryarahinduwe neza.",
    "language-updated": "Ururimi rwahinduwe neza.",
    "palette-updated": "Amabara yahinduwe neza.",
    "image-uploaded": "Ifoto yashyizweho neza.",
    "logo-removed": "Ifoto yakuweho neza.",
    "logo-remove-error": "Ikosa mu gukuraho logo: ",
    "updated-welcome": "Ubutumwa bw'akaze bushyizweho neza.",
    "updated-footer": "Ibimenyetso bya footer byashyizweho neza.",
    "updated-paragraph": "Inyandiko ya paragarafu yihariye yashyizweho neza.",
    "updated-supportemail": "Imeri y'ubufasha yashyizweho neza.",
    "stt-success": "Preferences za speech-to-text zabitswe neza.",
    "tts-success": "Preferences za text-to-speech zabitswe neza.",
    "failed-chats-export": "Byanze gutanga chats.",
    "chats-exported": "Imyiganirano yoherejwe neza nka {{name}}.",
    "cleared-chats": "Chats zose zirahanagurwa.",
    "embed-deleted": "Embed yavanywe muri system.",
    "snippet-copied": "Snippet yashyizwe mu kibanza cy'ibyo wandika!",
    "embed-updated": "Embed yashyizwe mu buryo bushya neza.",
    "embedding-saved": "Preferences za embedding zabitswe neza.",
    "chunking-settings": "Gukata inyandiko byashyizweho neza.",
    "llm-saved": "Preferences za LLM zabitswe neza.",
    "llm-saving-error": "Ntibishoboye kubika igenamiterere rya LLM: ",
    "multiuser-enabled": "Imikorere ya multi-user yashyizweho neza.",
    "publicuser-enabled": "Imikorere ya public-user yashyizweho neza.",
    "publicuser-disabled": "Imikorere ya public-user yavanyweho neza.",
    "page-refresh":
      "Urupapuro rwawe ruzashyirwa mu buryo bushya mu masegonda make.",
    "transcription-saved": "Preferences za transcription zabitswe neza.",
    "vector-saved": "Preferences za vector database zabitswe neza.",
    "workspace-not-deleted": "Workspace ntiyashoboye gusibwa!",
    "maximum-messages": "Ubutumwa bwa 4 byemewe.",
    "users-updated": "Abakoresha bashyizweho neza.",
    "vectordb-not-reset": "Workspace vector database ntiyashobora gusubizwamo!",
    "vectordb-reset": "Workspace vector database yarasubizwemo!",
    "meta-data-update": "Amahitamo ya website yavuguruwe!",
    "linked-workspaces-updated": "Ahantu habigenewe havuguruwe neza.",
    "appname-update-error":
      "Kuvugurura izina ryihariye rya application byanze: ",
    "logo-uploaded": "Logo yashyizweho neza.",
    "logo-upload-error": "Gushyiraho logo byanze: ",
    "update-welcome-error": "Kuvugurura ubutumwa bwo kwakira byanze:",
    "update-footer-error": "Kuvugurura udushushondanga two hasi byanze: ",
    "update-paragraph-error": "Kuvugurura interuro yihariye byanze: ",
    "update-supportemail-error": "Kuvugurura imeri yo gufasha byanze: ",
    "reset-tab-name-error":
      "Gusubiza izina rya tab ku miterere yayo ya mbere byanze.",
    "update-tab-name-error": "Kuvugurura amazina ya tabs byanze: ",
    "updated-website": "Amahitamo ya website yavuguruwe neza.",
    "update-website-error": "Kuvugurura linki ya website byanze: ",
    "reset-website-error":
      "Gusubiza linki ya website ku miterere yayo ya mbere byanze.",
    "palette-update-error": "Ntibyashobotse kuvugurura urutonde rw'amabara: ",
    "citation-state-updated":
      "Imiterere y'amagambo yavuguruwe neza. {{citationState}}",
    "citation-state-update-error":
      "Kuvugurura imiterere y'amagambo ntibyakunze",
    "citation-update-error": "Habaye ikosa mu kuvugurura imiterere y'amagambo",
    "message-limit-updated": "Ibipimo by'umubare w'ubutumwa byavuguruwe neza.",
    "upgrade-text-error": "Ntibyashobotse kuvugurura inyandiko: ",
    "invoice-logging-state-updated":
      "Amahitamo yo kwandika inyemezabuguzi yavuguruwe neza.",
    "invoice-logging-state-update-error":
      "Habaye ikosa mu ivugurura ry'iyandikwa ry'inyemezabuguzi: ",
    "active-case": {
      "reference-updated": "Icyerekezo cy'urubanza cyavuguruwe neza",
      "reference-cleared": "Icyerekezo cy'urubanza cyakuweho neza",
    },
    "file-upload-success": "Dosiye yashyizweho neza",
    "files-upload-success": "Dosiye {{count}} zashyizweho neza",
    "file-upload-error": "Habaye ikosa mu gushyiraho dosiye",
    "file-removed": "Dosiye yakuweho neza",
    "file-remove-error":
      "Ntabwo dosiye yashoboye gukurwa neza. Ongera ugerageze.",
    "link-upload-success": "Ihuza ryashyizweho neza",
    "link-upload-error": "Habaye ikosa mu gushyiraho ihuza: {{error}}",
    "qura-login-success": "Kwinjira muri Qura byagenze neza",
    "youtube-transcription-complete":
      "Inyandiko ya {{title}} na {{author}} yarangiye. Aho ibikwa ni {{destination}}.",
    "youtube-transcription-error":
      "Habaye ikosa mu kwandika video ya YouTube: {{error}}",
    "github-repo-error":
      "Habaye ikosa mu gukoresha GitHub repository: {{error}}",
    "gitlab-repo-error":
      "Habaye ikosa mu gukoresha GitLab repository: {{error}}",
    "confluence-error": "Habaye ikosa mu gukoresha Confluence: {{error}}",
    "website-error": "Habaye ikosa mu gukoresha urubuga: {{error}}",
    "invalid-credentials": "Izina cyangwa ijambo ry'ibanga sibyo",
    "fill-all-fields": "Nyamuneka uzuza ibice byose",
    "qura-login-error": "Izina cyangwa ijambo ry'ibanga sibyo",
    "error-linking-rexor": "Ikosa mu kuzana imimerere y'ihuriro rya Rexor",
    "time-transaction-registered": "Igenamigambi ry'igihe ryanditswe neza",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri OpenAI",
      ],
    },
    azure: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zihuza ntabwo zigaragara kuri OpenAI cyangwa Microsoft",
      ],
    },
    anthropic: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Anthropic",
      ],
    },
    gemini: {
      description: [
        "Ibiganirizwa byawe birahinduwe ibimenyetso no gukoresha mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Google",
      ],
    },
    lmstudio: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha LMStudio",
      ],
    },
    localai: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha LocalAI",
      ],
    },
    ollama: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku mashini ikoreshwa na Ollama",
      ],
    },
    native: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa kuri iyi instance ya platform",
      ],
    },
    togetherai: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Mistral",
      ],
    },
    huggingface: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza byoherezwa kuri endpoint ya HuggingFace",
      ],
    },
    perplexity: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri OpenRouter",
      ],
    },
    groq: {
      description: [
        "Ibiganirizwa byawe ntibizakoreshwa mu kwigisha",
        "Ibyo wanditse n'inyandiko zikoreshwa mu gusubiza biragaragara kuri Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha Oobabooga Text Generation Web UI",
      ],
    },
    "generic-openai": {
      description: [
        "Données partagées selon les conditions de service applicables avec votre fournisseur de point de terminaison générique.",
      ],
    },
    cohere: {
      description: [
        "Les données sont partagées conformément aux conditions de service de cohere.com et aux lois sur la confidentialité de votre localité.",
      ],
    },
    litellm: {
      description: [
        "Imiterere yawe n'ibiganirizwa byawe biraboneka gusa ku murongo ukoresha LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Chroma",
        "Kugera kuri instance yawe biragenzurwa na wewe",
      ],
    },
    pinecone: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku mashini za Pinecone",
        "Kugera ku makuru yawe biragenzurwa na Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Qdrant (cloud cyangwa auto-hébergée)",
      ],
    },
    weaviate: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Weaviate (cloud cyangwa auto-hébergée)",
      ],
    },
    milvus: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa ku instance yawe Milvus (cloud cyangwa auto-hébergée)",
      ],
    },
    zilliz: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa kuri cluster yawe ya Zilliz.",
      ],
    },
    astra: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa kuri base de données cloud AstraDB.",
      ],
    },
    lancedb: {
      description: [
        "Ibyo wanditse n'inyandiko zikoreshwa birabikwa mu buryo bwihariye kuri iyi instance ya platform",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye kuri iyi instance ya platform",
      ],
    },
    openai: {
      description: [
        "Teksto y'inyandiko zawe yoherezwa ku mashini za OpenAI",
        "Ibyo wanditse ntabwo bikoreshwa mu kwigisha",
      ],
    },
    azure: {
      description: [
        "Teksto y'inyandiko zawe yoherezwa kuri serivisi ya Microsoft Azure",
        "Ibyo wanditse ntabwo bikoreshwa mu kwigisha",
      ],
    },
    localai: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye ku murongo ukoresha LocalAI",
      ],
    },
    ollama: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye ku murongo ukoresha Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Teksto y'inyandiko zawe ikozwe mu buryo bwihariye ku murongo ukoresha LMStudio",
      ],
    },
    cohere: {
      description: [
        "Données partagées conformément aux conditions de service de cohere.com et aux lois sur la confidentialité de votre localité.",
      ],
    },
    voyageai: {
      description: [
        "Les données envoyées aux serveurs Voyage AI sont partagées conformément aux conditions de service de voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Hindura",
    response: "Igisubizo",
    prompt: "Gusaba",
    regenerate: "Kongera gukora igisubizo",
    good: "Igisubizo cyiza",
    bad: "Igisubizo kibi",
    copy: "Gukoporora",
    more: "Ibindi bikorwa",
    fork: "Koporora",
    delete: "Gusiba",
    cancel: "Guhagarika",
    save: "Bika & Ohereza",
  },

  citations: {
    show: "Erekana Isoko",
    hide: "Hisha Isoko",
    chunk: "Ibice by'Isoko",
    pdr: "Inyandiko y'Ishakiro Yabonetse",
    "pdr-h": "Kwibanda ku Nyandiko Soko",
    referenced: "Byavuzwe",
    times: "incuro.",
    citation: "Isoko",
    match: "bahuye",
    download:
      "Iki gikoresho ntigifasha kureba PDFs. Nyamuneka mukure PDF kugirango mubashe kuyireba :",
    "download-btn": "Kuramo PDF",
  },

  "document-drafting": {
    title: "Gutunganya Inyandiko",
    description: "Genzura uburyo bwo gutunganya inyandiko.",
    configuration: "Gushiraho",
    "drafting-model": "Icyifuzo cya LLM cyo Gutunganya Inyandiko",
    enabled: "Gutunganya Inyandiko byarakozwe",
    disabled: "Gutunganya Inyandiko birahagaritswe",
    "enabled-toast": "Gutunganya Inyandiko byarakozwe",
    "disabled-toast": "Gutunganya Inyandiko birahagaritswe",
    "desc-settings":
      "Umuyobozi ashobora guhindura uburyo bwo gutunganya inyandiko ku bakoresha bose.",
    "drafting-llm": "Icyifuzo cya LLM cyo Gutunganya Inyandiko",
    saving: "Birimo gukorwa...",
    save: "Bika impinduka",
    "chat-settings": "Igenamiterere rya Chat",
    "drafting-chat-settings": "Igenamiterere rya Chat ryo Kwandika Amadosiye",
    "chat-settings-desc":
      "Genzura imyitwarire y'igikoresho cya chat mu gihe cyo kwandika amadosiye.",
    "drafting-prompt": "Gusaba sisitemu yo gukora inyandiko",
    "drafting-prompt-desc":
      "Uburyo sisitemu ikoreshwa mu gukora inyandiko butandukanye n'uburyo bw'ikibazo n'igisubizo cya sisitemu y'amategeko. Gusaba sisitemu yo gukora inyandiko bigena uburyo n'amabwiriza bigamije gufasha AI gutanga igisubizo. Ugomba gutanga guhamagara gushyizwe mu murongo neza kugira ngo AI ibashe gutanga igisubizo gikwiriye kandi cyizewe.",
    linking: "Guhuza inyandiko",
    "legal-issues-prompt":
      "Ni ibibazo by'amategeko biva mu mwanya wahawe hamwe na prompt?",
    "memo-prompt": "Tanga memorandum kuri buri kibazo cy'amategeko.",
    "legal-issues-prompt-desc": "Injiza prompt ku bibazo by'amategeko.",
    "memo-prompt-desc": "Injiza prompt ku memo.",
    "desc-linkage":
      "Enable adding further legal context by doing Vector/PDR searches on top of memo fetching",
    message: {
      title: "Ubutumwa Bwasabwe mu Kwandika Inyandiko",
      description:
        "Ongeramo ubutumwa bwasabwe abakoresha bashobora guhita bahitamo mu gihe cyo kwandika inyandiko.",
      heading: "Umutwe w'Ubutumwa Busanzwe",
      body: "Igice cy'Ubutumwa Busanzwe",
      "new-heading": "Umutwe w'Ubutumwa",
      message: "Ibikubiye mu Butumwa",
      add: "Ongeramo Ubutumwa",
      save: "Bika Ubutumwa",
    },
  },

  "dd-settings": {
    title: "Igenamiterere y'Inyandiko",
    description:
      "Kugenzura imipaka ya tokens n'imyitwarire y'ibikoresho byo kwandika inyandiko",
    "vector-search": {
      title: "Gushakisha Vector",
      description:
        "Gukoresha gushakisha vector kubona inyandiko zifitanye isano",
    },
    "memo-generation": {
      title: "Gukora Imyandiko",
      description: "Gukora imyandiko y'ibibazo by'amategeko ku bushakashatsi",
    },
    "base-generation": {
      title: "Isesengura ry'Ibanze ry'Amategeko",
      description:
        "Kugena isesengura ry'ibanze ry'ibibazo by'amategeko bishingiye ku bibazo by'abakoresha",
    },
    "linked-workspace-impact": {
      title: "Ingaruka ku Mbuga Zihujwe",
      description:
        "Guhuza tokens ziboneka hashingiwe ku mubare w'imbuga zihujwe",
    },
    "vector-token-limit": {
      title: "Umupaka wa Tokens za Vector",
      description:
        "Umubare ntarengwa wa tokens kuri buri workspace ihujwe ku ishakisha rya vector",
    },
    "memo-token-limit": {
      title: "Umupaka wa Tokens z'Imyandiko",
      description:
        "Umubare ntarengwa wa tokens zo gukora imyandiko y'amategeko",
    },
    "base-token-limit": {
      title: "Umupaka wa Tokens z'Ibanze",
      description:
        "Umubare ntarengwa wa tokens zo gufata ibiri mu nyandiko z'ibanze",
    },
    "toast-success": "Igenamiterere ryavuguruwe neza",
    "toast-fail": "Kuvugurura igenamiterere birananiranye",
  },

  modale: {
    document: {
      title: "Inyandiko Zanjye",
      document: "Inyandiko",
      search: "Shakisha inyandiko",
      folder: "Idosiye Nshya",
      name: "Izina",
      empty: "Nta Nyandiko",
      "move-workspace": "Imura mu Murimo",
      "doc-processor": "Gutunganya Inyandiko Ntiboneka",
      "processor-offline":
        "Ntidushobora gushyira inyandiko zanyu ubu kuko gutunganya inyandiko byafunze. Nyamuneka gerageza nyuma.",
      "drag-drop": "Kanda kugirango ushyireho cyangwa ukuremo",
      "supported-files":
        "ishyigikira inyandiko, csv, impapuro z'ikubitiro, amajwi, n'ibindi byinshi!",
      "submit-link": "cyangwa shyiramo umuyoboro",
      fetch: "Kuramo urubuga",
      fetching: "Kuramo...",
      "file-desc":
        "Izi nyandiko zizashyirwa ku gutunganya inyandiko bikorwa kuri iyi sisitemu. Izi nyandiko ntizoherezwa cyangwa zigasangirwa n'undi muntu.",
      cost: "*Ikiguzi kimwe cy'ibihangano",
      "save-embed": "Bika kandi Ushyireho",
      "loading-message": "Ibi bishobora gufata igihe kinini ku nyandiko nini",
      "failed-uploads": "Ntibishobotse kohereza izi mbuga zikurikira",
    },
    connectors: {
      title: "Connecteurs de Données",
      search: "Shakisha Connecteurs de Données",
      empty: "Nta Connecteurs de Données babonetse.",
    },
  },

  dataConnectors: {
    github: {
      name: "Ububiko GitHub",
      description:
        "Injiza ububiko bwa GitHub rusange cyangwa bwihariye mu kanda rimwe gusa.",
      url: "URL y'Ububiko GitHub",
      "collect-url": "URL y'ububiko GitHub ushaka gukusanya.",
      "access-token": "Ikarita y'Ububiko GitHub",
      optional: "bidakenewe",
      "rate-limiting":
        "Ikarita y'Ububiko kugirango wirinde igipimo cyo gufunga.",
      "desc-picker":
        "Nimara kurangira, amadosiye yose azaboneka kugirango ashyirwe mu bibanza by'akazi muri mudasobwa y'amadosiye.",
      branch: "Ishami",
      "branch-desc": "Ishami ushaka gukusanya amadosiye.",
      "branch-loading": "-- gupakira amashami aboneka --",
      "desc-start": "Utuzuza",
      "desc-token": "Ikarita y'Ububiko GitHub",
      "desc-connector": "uyu mukozi w'amakuru azabasha gukusanya gusa",
      "desc-level": "amadosiye yo hejuru",
      "desc-end": "y'ububiko kubera igipimo cyo gufunga API rusange ya GitHub.",
      "personal-token":
        "Fungura ikarita y'ububiko y'umuntu ku giti cye hamwe na konte ya GitHub hano.",
      without: "Utari kumwe n'akarita y'ububiko y'umuntu ku giti cye",
      "personal-token-access": "Ikarita y'Ububiko y'Umuntu",
      "desc-api":
        ", API ya GitHub ishobora gufunga umubare w'amadosiye ashobora gukusanywa kubera igipimo cyo gufunga. Ushobora",
      "temp-token": "gukora ikarita y'ububiko y'agateganyo",
      "avoid-issue": "kugirango wirinde iki kibazo.",
      submit: "Ohereza",
      "collecting-files": "Gukusanya amadosiye...",
    },
    "youtube-transcript": {
      name: "Transcription ya YouTube",
      description:
        "Injiza transcription y'ikiganiro cyose cya YouTube uhereye ku murongo.",
      url: "URL y'Ikiganiro cya YouTube",
      "url-video": "URL y'ikiganiro cya YouTube ushaka gutandukanya.",
      collect: "Kusanya transcription",
      collecting: "Gukusanya transcription...",
      "desc-end":
        "Nimara kurangira, transcription izaboneka kugirango ishyirwe mu bibanza by'akazi muri mudasobwa y'amadosiye.",
    },
    "website-depth": {
      name: "Ububiko bwinshi bwa Link",
      description:
        "Kusanya urubuga n'uburinganire bwayo kugeza ku rugero runaka.",
      url: "URL y'Urubuga",
      "url-scrape": "URL y'urubuga ushaka gukusanya.",
      depth: "Ubujyakuzimu",
      "child-links":
        "Uyu ni umubare w'uburinganire bugomba gukurikizwa kuva kuri URL y'umwimerere.",
      "max-links": "Links z'Ububiko",
      "links-scrape": "Umubare ntarengwa w'uburinganire bugomba gukusanywa.",
      scraping: "Gukusanya urubuga...",
      submit: "Ohereza",
      "desc-scrap":
        "Nimara kurangira, amapaji yose yakusanyijwe azaboneka kugirango ashyirwe mu bibanza by'akazi muri mudasobwa y'amadosiye.",
    },
    confluence: {
      name: "Confluence",
      description: "Injiza urupapuro rwose rwa Confluence mu kanda rimwe gusa.",
      url: "URL y'Urupapuro rwa Confluence",
      "url-page": "URL y'urupapuro mu gace ka Confluence.",
      username: "Izina ry'Umukoresha Confluence",
      "own-username": "Izina ryawe ry'umukoresha Confluence.",
      token: "Ikarita y'Ububiko Confluence",
      "desc-start":
        "Ukeneye gutanga ikarita y'ububiko kugirango ukore authentification. Ushobora gukora ikarita y'ububiko",
      here: "hano",
      access: "Ikarita y'ububiko kugirango ukore authentification.",
      collecting: "Gukusanya amapaji...",
      submit: "Ohereza",
      "desc-end":
        "Nimara kurangira, amapaji yose azaboneka kugirango ashyirwe mu bibanza by'akazi.",
    },
  },

  module: {
    "legal-qa": "Ibibazo n'Ibisubizo by'Amategeko",
    "document-drafting": "Gutunganya Inyandiko",
    "active-case": "Urubanza Rukora",
  },

  "fine-tune": {
    title: "Ufite amakuru ahagije yo gukosora byimbitse!",
    link: "kanda hano kugira ngo umenye byinshi",
    dismiss: "fungura",
  },

  mobile: {
    disclaimer:
      "INTEGUZA: Kugira ngo ukoreshe neza kandi ubone ibyiza byose, koresha mudasobwa kugira ngo winjire muri iyi porogaramu.",
  },

  "default-settings": {
    title: "Igenamiterere Rya Default ku Mibazo N'ibisubizo by'Amategeko",
    "default-desc":
      "Genzura imyitwarire isanzwe y'ibyumba by'akazi ku Mibazo N'ibisubizo by'Amategeko",
    prompt: "Guhamagara Sisitemu y'ibibazo by'Amategeko",
    "prompt-desc":
      "Guhamagara bisanzwe bizakoreshwa mu Mibazo N'ibisubizo by'Amategeko. Tangaza aho bisohokera hamwe n'amabwiriza kugira ngo AI ikore igisubizo. Ugomba gutanga igisubizo cyatekerejwe neza kugira ngo AI ibashe gutanga igisubizo gikwiriye kandi cyizewe.",
    "toast-success": "Guhamagara bisanzwe bwa sisitemu byavuguruwe",
    "toast-fail": "Ntibyashobotse kuvugurura guhamagara bwa sisitemu",
    snippets: {
      title: "Umubare ntarengwa w'ubusobanuro bw'inyandiko",
      description:
        "Iyi myanya igenzura umubare ntarengwa w'ubusobanuro bw'inyandiko bizahabwa LLM kuri buri kiganiro cyangwa ikibazo.",
      recommend: "Birakwiye: 10",
    },
    "rerank-limit": {
      title: "Umubare ntarengwa w'inyandiko zongera gushyirwa mu byiciro",
      description:
        "Iyi myanya igenzura umubare ntarengwa w'inyandiko zizafatwa mu kongera gushyirwa mu byiciro. Umubare munini ushobora gutanga ibisubizo byiza ariko bizongera igihe cyo gutunganya.",
      recommend: "Birakwiye: 50",
    },
    "validation-prompt": {
      title: "Inyandiko yo Kugenzura",
      description:
        "Aya matsinda agena inyandiko ngenderwaho yo kugenzura izohererezwa LLM kugira ngo igenzure igisubizo cyatanzwe.",
      placeholder:
        "Nyamuneka genzura igisubizo gikurikira, ugenzura ko indangagaciro zose z'amategeko n'amagambo yakoreshejwe ari ukuri ugereranyije n'ibiri mu mwandiko. Andika amakosa yose cyangwa ibisobanuro bitari byo byabonetse.",
    },
  },

  "confirm-message": {
    "delete-doc":
      "Wizeye neza ko ushaka gusiba aya madosiye n'amadosiye? \nIbi bizasiba amadosiye muri sisitemu kandi biyakure mu buryo bwikora mu mbuga z'akazi ziriho.\nIyi gahunda ntishobora gusubizwamo.",
  },
  performLegalTask: {
    title: "Gukora Akazi ka Remezo",
    description:
      "Shyira cyangwa uhagarike buto yo gukora imirimo y'amategeko mu kwandika inyandiko.",
    successMessage: "Akazi ka remezo kamaze kuba {{status}}",
    failureUpdateMessage:
      "Ntibyashobotse kuvugurura gahunda y'akazi ka remezo.",
    errorSubmitting: "Ikosa ryabonetse mu kohereza gahunda y'akazi ka remezo.",
  },
  canvasChat: {
    title: "Canvas",
    "input-placeholder": "Saba amakuru ajyanye n'amategeko",
  },
  statuses: {
    enabled: "cyemewe",
    disabled: "cyahagaritswe",
  },
  "answer-upgrade": {
    title: "Kunoza Igisubizo",
    subtitle: "Hitamo icyiciro cy'imbere",
    steps: "Gukora imirimo y’amategeko",
    planning: "Kwiga...",
    "cancel-process": "Uzi neza ko ushaka guhagarika uyu murimo w’amategeko?",
    "wait-process": "Mwihangane uko turakora ukoze.",
    "category-step": {
      title: "Hitamo Icyiciro",
      description: "Hitamo icyiciro gihuje neza n'ibyo ukeneye",
      categories: {
        formality: {
          label: "Uburyo bwo Kuvuga",
          choices: {
            more_formal: "Kora mu buryo bwubahitse kurushaho",
            less_formal: "Kora mu buryo butubahitse",
            more_professional: "Kora mu buryo bw'umwuga kurushaho",
            more_casual: "Kora mu buryo busanzwe",
            more_polished: "Kora mu buryo bunoze kurushaho",
            more_relaxed: "Kora mu buryo budasesekaye",
            academic_tone: "Koresha imvugo y'ubumenyi",
            conversational_tone: "Koresha imvugo yo kuganira",
          },
        },
        complexity: {
          label: "Ubudakemwa bw'Ururimi",
          choices: {
            simplify: "Koroshya ururimi",
            more_descriptive: "Ongeramo imvugo isobanura kurushaho",
            complex_vocab: "Koresha amagambo akomeye kurushaho",
            simple_vocab: "Koresha amagambo yoroshye",
            technical: "Ongeramo imvugo y'ubuhanga",
            layman: "Koresha imvugo isanzwe",
            add_jargon: "Ongeramo amagambo y'umwuga",
            avoid_jargon: "Irinde amagambo y'umwuga ukoreshe amagambo rusange",
            add_rhetorical: "Ongeramo ibibazo by'iyobokamana",
            less_rhetorical: "Koresha bike ibibazo by'iyobokamana",
          },
        },
        structure: {
          label: "Imiterere y'Interuro",
          choices: {
            shorter: "Gucishiriza interuro",
            longer: "Kongerera interuro uburebure",
            vary: "Hindura imiterere y'interuro",
            standardize: "Kora interuro zisa",
            more_complex: "Koresha interuro zikomeye kurushaho",
            simpler: "Koresha interuro zoroshye",
            active_voice: "Ongeramo imvugo itaziguye",
            passive_voice: "Ongeramo imvugo iziguye",
          },
        },
        figurative: {
          label: "Imvugo Ishushanya",
          choices: {
            more_figurative: "Koresha imvugo ishushanya kurushaho",
            less_figurative: "Kugabanya imvugo ishushanya",
            metaphors: "Ongeramo ingero n'ibigereranyo",
            literal: "Koresha imvugo isobanutse",
            more_idioms: "Ongeramo imvugo ngengamico",
            less_idioms: "Gabanya imvugo ngengamico",
            more_symbolism: "Noza imikoreshereze y'ibimenyetso",
            less_symbolism: "Gabanya imikoreshereze y'ibimenyetso",
          },
        },
        conciseness: {
          label: "Kugira Incamake",
          choices: {
            more_concise: "Kora mu buryo bugufi",
            more_wordy: "Kora mu buryo burambuye",
            remove_redundant: "Kuraho interuro zidakenewe",
            add_details: "Ongeramo ibisobanuro birambuye",
            reduce_filler: "Gabanya amagambo y'inyongera",
            add_elaboration: "Ongeramo ibisobanuro byimbitse",
          },
        },
        imagery: {
          label: "Imvugo Ishushanya n'Ibisobanuro by'Ibyumviro",
          choices: {
            enhance_imagery: "Noza imvugo ishushanya",
            simplify_imagery: "Koroshya imvugo ishushanya",
            vivid_descriptions: "Koresha ibisobanuro bisobanutse neza",
            straightforward_descriptions: "Koresha ibisobanuro bitaziguye",
            more_visual: "Ongeramo ibisobanuro bigaragara",
            less_visual: "Gabanya ibisobanuro bigaragara",
          },
        },
        paragraph: {
          label: "Imiterere y'Ibika n'Inyandiko",
          choices: {
            shorter_paragraphs: "Gucishiriza ibika",
            longer_paragraphs: "Kongerera ibika uburebure",
            break_sections: "Gucamo ibice mu bice bito",
            combine_sections: "Huza ibice kugira ngo bigende neza",
            more_lists: "Koresha urutonde rwinshi",
            more_continuous: "Koresha inyandiko ikomeza",
            vary_paragraphs: "Ongeramo uburyo butandukanye bw'ibika",
            consistent_length: "Gumana uburebure bumwe bw'ibika",
          },
        },
        other: {
          label: "Ibindi Bintu",
          choices: {
            replace_context:
              "Gusimbuza CONTEXT-references n'izina ry'inkomoko nyayo",
            add_numbering: "Ongeramo nomero z'ibika",
            remove_numbering: "Kuraho nomero z'ibika",
            extend_statutories: "Ongeramo inyandiko ku mategeko",
            reduce_statutories: "Gabanya inyandiko ku mategeko",
            extend_jurisprudence: "Ongeramo inyandiko ku mategeko ngenderwaho",
            reduce_jurisprudence: "Gabanya inyandiko ku mategeko ngenderwaho",
          },
        },
      },
    },
    "prompt-step": {
      title: "Hitamo Prompt",
      description: "Hitamo uburyo ushaka kunoza igisubizo",
    },
    actions: {
      next: "Komeza",
      back: "Gusubira Inyuma",
      upgrade: "Noza Igisubizo",
      cancel: "Guhagarika",
    },
  },

  "pdr-settings": {
    title: "Igenamiterere rya PDR",
    description:
      "Tegura igenamiterere rya Parent Document Retrieval ku myanya yawe y'akazi.",
    "desc-end":
      "Aya magenamiterere agira ingaruka ku buryo inyandiko za PDR zitunganywa kandi zikoreshwa mu bisubizo by'ibiganiro.",
    "global-override": {
      title: "Gusimbuza PDR Ihinduka ku Rwego rw'Isi",
      description:
        "Iyo ikoreshejwe, ibi bizatuma PDR Ihinduka ikoreshwa ku myanya yose y'akazi, hatitawe ku magenamiterere yayo bwite.",
    },
    "pdr-token-limit": "Urugero rw'Ibimenyetso bya PDR",
    "pdr-token-limit-desc":
      "Umubare ntarengwa w'ibimenyetso bizakoreshwa mu mikorere ya PDR.",
    "input-prompt-token-limit": "Urugero rw'Ibimenyetso by'Iyinjiza",
    "input-prompt-token-limit-desc":
      "Umubare ntarengwa w'ibimenyetso bizakoreshwa mu iyinjiza.",
    "response-token-limit": "Urugero rw'Ibimenyetso by'Igisubizo",
    "response-token-limit-desc":
      "Umubare ntarengwa w'ibimenyetso bizakoreshwa mu gisubizo.",
    "toast-success": "Amagenamiterere ya PDR yavuguruwe",
    "toast-fail": "Kuvugurura amagenamiterere ya PDR byanze",
    "adjacent-vector-limit": "Urugero rwa Vecteurs Zegeranye",
    "adjacent-vector-limit-desc":
      "Umubare wa vecteurs zegeranye zizakoreshwa mu mikorere ya PDR.",
    "keep-pdr-vectors": "Bika Vecteurs z'Inyandiko za PDR",
    "keep-pdr-vectors-desc":
      "Iyo bikoreshejwe, inyandiko za PDR zuzuye n'ibice byazo bya vecteur bizashyirwa mu mwihariko. Ibi bishobora gutanga umwihariko urambuye ariko bikoresha tokens nyinshi.",
  },
  "validate-response": {
    title: "Igisubizo cy'igenzura",
    "toast-fail": "Kwemeza igisubizo byarananiranye",
    validating: "Kwemeza igisubizo",
    button: "Emeza igisubizo",
    "adjust-prefix":
      "Kora impinduka zose zagaragajwe mu gisubizo ukurikije ibi bitekerezo: ",
    "adjust-button": "Hindura igisubizo nk'uko bikwiye",
  },

  "workspace-names": {
    "Administrative Law": "Amategeko yo mu nzego za leta",
    "Business Law": "Amategeko y'ubucuruzi",
    "Civil Law": "Amategeko y'imbonezamubano",
    "Criminal Law": "Amategeko ahana",
    "Diplomatic Law": "Amategeko y'ububanyi n'amahanga",
    "Fundamental Law": "Amategeko shingiro",
    "Human Rights Law": "Amategeko agenga uburenganzira bwa muntu",
    "Judicial Laws": "Amategeko y'ubutabera",
    "Security Laws": "Amategeko y'umutekano",
    "Taxation Laws": "Amategeko y'imisoro",
  },
  "validate-answer": {
    setting: "Igenzura rya LLM",
    title: "Imyitozo yo Kugenzura LLM",
    description:
      "Ibi ni ibisobanuro n'imiterere ya validation LLM chat & embedding yihariye. Ni ngombwa ko aya makuru ari nyayo kandi agezweho, ubundi sisitemu ntizakora neza.",
    "toast-success": "Igenamiterere rya validation LLM ryaravuguruwe",
    "toast-fail": "Ntibyashobotse kuvugurura igenamiterere rya validation LLM",
    saving: "Kubika...",
    "save-changes": "Bika impinduka",
  },
  "active-case": {
    title: "Injiza nimero y'icyerekezo cy'urubanza",
    placeholder: "urugero 1000-01",
    "select-reference": "Hitamo icyerekezo cy'urubanza",
  },

  // TextGenWebUI Component
  textgenwebui: {
    "base-url": "URL y'ibanze",
    "base-url-placeholder": "http://127.0.0.1:5000/v1",
    "token-window": "Idirishya ry'umwirondoro",
    "token-window-placeholder": "Urugero rw'idirishya (urugero: 4096)",
    "api-key": "Urufunguzo rwa API (Bishoboka)",
    "api-key-placeholder": "Urufunguzo rwa API ya TextGen Web UI",
    "max-tokens": "Umubare ntarengwa wa tokens",
    "max-tokens-placeholder":
      "Umubare ntarengwa wa tokens ku cyifuzo (urugero: 1024)",
  },

  // LMStudio Component
  lmstudio: {
    "max-tokens": "Umubare ntarengwa wa tokens",
    "max-tokens-desc": "Umubare ntarengwa wa tokens ku mwirondoro n'igisubizo.",
    "show-advanced": "Erekana ibikoresho by'inyongera",
    "hide-advanced": "Hisha ibikoresho by'inyongera",
    "base-url": "URL y'ibanze ya LM Studio",
    "base-url-placeholder": "http://localhost:1234/v1",
    "base-url-desc": "Andika URL aho LM Studio ikora.",
    "auto-detect": "Kubona byikora",
    model: "Modeli ya LM Studio",
    "model-loading": "--itangira kuzana modeli ziboneka--",
    "model-url-first": "Andika URL ya LM Studio mbere",
    "model-desc":
      "Hitamo modeli ya LM Studio ushaka gukoresha. Modeli zizazanwa nyuma yo kwandika URL nyayo ya LM Studio.",
    "model-choose":
      "Hitamo modeli ya LM Studio ushaka gukoresha mu biganiro byawe.",
    "model-loaded": "Modeli zawe zatanzwe",
    "embedding-required":
      "LMStudio nka LLM yawe isaba ko ushyiraho serivisi yo kwinjiza ugakoresha.",
    "manage-embedding": "Gucunga kwinjiza",
  },

  // LocalAI Component
  localai: {
    "token-window": "Idirishya ry'umwirondoro",
    "token-window-placeholder": "4096",
    "api-key": "Urufunguzo rwa API ya Local AI",
    "api-key-optional": "bishoboka",
    "api-key-placeholder": "sk-mysecretkey",
    "show-advanced": "Erekana amagenamiterere y'inyongera",
    "hide-advanced": "Hisha amagenamiterere y'inyongera",
    "base-url": "URL y'ibanze ya Local AI",
    "base-url-placeholder": "http://localhost:8080/v1",
    "auto-detect": "Kubona byikora",
  },

  // Confluence Connector Component
  confluence: {
    "space-key": "Urufunguzo rw'umwanya wa Confluence",
    "space-key-desc":
      "Uru ni urufunguzo rw'umwanya wa Confluence wawe uzakoreshwa. Akenshi utangira na ~",
    "space-key-placeholder": "urugero: ~7120208c08555d52224113949698b933a3bb56",
    "url-placeholder":
      "urugero: https://example.atlassian.net, http://localhost:8211, etc...",
    "token-tooltip": "Ushobora gukora urufunguzo rwa API",
    "token-tooltip-here": "hano",
  },

  // Legal Categories
  "legal-categories": {
    // Process/Legal Proceedings
    process: "Urubanza",
    "process-stamning": "Ikirego",
    "process-svaromal": "Isubizo ku kirego",
    "process-yrkanden": "Ibyifuzo n'imyanzuro",

    // Agreements
    avtal: "Amasezerano",
    "avtal-anstallning": "Amasezerano y'akazi",
    "avtal-finansiering": "Amasezerano y'inguzanyo n'ubwishingire",
    "avtal-licens": "Amasezerano y'uburenganzira bwo gukoresha",

    // Due Diligence
    "due-diligence": "Iperereza ry'ubucuruzi",
    "due-diligence-avtal": "Igenzura ry'amasezerano",
    "due-diligence-checklista": "Urutonde rw'igenzura",
    "due-diligence-compliance": "Igenzura ryo kubahiriza amategeko",
  },

  "dynamic-pdr": {
    title: "PDR Ihinduka ku Mwanya w'Akazi",
    description:
      "Kuzamura cyangwa gucisha PDR Ihinduka kuri uyu mwanya w'akazi.",
    "global-enabled":
      "PDR Ihinduka ikoreshwa ku rwego rw'isi kandi ntishobora gucishwa ku myanya y'akazi yihariye.",
  },
  "document-builder": {
    title: "Ibisobanuro by'Ubwubatsi bw'inyandiko",
    description: "Genzura ibisobanuro by' ubwubatsi bw'inyandiko.",
    "action-plan-prompt": "Prompt ya Sisteme y'Integanyanyigisho",
    "action-plan-prompt-desc":
      "Prompt ya sisteme y'integanyanyigisho ifasha LLM gukora integanyanyigisho yo kubaka inyandiko zemewe n'amategeko.",
    "action-plan-prompt-placeholder":
      "Injiza prompt ya sisteme y'integanyanyigisho...",
    "legal-task-prompt": "Prompt y'Umukoresha w'Igikorwa Cy'Amategeko",
    "legal-task-prompt-desc":
      "Prompt y'umukoresha w'igikorwa cy'amategeko ifasha LLM gukora integanyanyigisho yihariye ishingiye ku gikorwa cyatoranijwe.",
    "legal-task-prompt-placeholder":
      "Injiza prompt y'umukoresha w'igikorwa cy'amategeko...",
    "toast-success": "Ibisobanuro byahinduwe neza",
    "toast-fail": "Ntibyakunze guhindura ibisobanuro",
    save: "Bika",
    saving: "Birabikwa...",
    "action-steps-system-prompt": "Prompt ya Sisteme y'Intambwe z'Ibikorwa",
    "action-steps-system-prompt-desc":
      "Prompt ya sisteme y'intambwe z'ibikorwa ifasha LLM gushyira mu bikorwa intambwe z'ibikorwa hashingiwe ku nteganyanyigisho.",
    "action-steps-system-prompt-placeholder":
      "Injiza prompt ya sisteme y'intambwe z'ibikorwa...",
    "action-steps-user-prompt": "Prompt y'Umukoresha w'Intambwe z'Ibikorwa",
    "action-steps-user-prompt-desc":
      "Prompt y'umukoresha w'intambwe z'ibikorwa ifasha LLM gukora ihuriro hagati ya prompt ya sisteme na buri ntambwe y'igikorwa.",
    "action-steps-user-prompt-placeholder":
      "Injiza prompt y'umukoresha w'intambwe z'ibikorwa...",
    "final-steps-system-prompt": "Prompt ya Sisteme y'Intambwe za Nyuma",
    "final-steps-system-prompt-desc":
      "Prompt ya sisteme y'intambwe za nyuma ifasha LLM gukora ihuriro hagati y'intambwe z'ibikorwa n'umusaruro wa nyuma.",
    "final-steps-system-prompt-placeholder":
      "Injiza prompt ya sisteme y'intambwe za nyuma...",
    "final-steps-user-prompt": "Prompt y'Umukoresha w'Intambwe za Nyuma",
    "final-steps-user-prompt-desc":
      "Prompt y'umukoresha w'intambwe za nyuma ifasha LLM gukora ihuriro hagati y'intambwe z'ibikorwa n'umusaruro wa nyuma.",
    "final-steps-user-prompt-placeholder":
      "Injiza prompt y'umukoresha w'intambwe za nyuma...",
    "action-plan-combine-prompt": "Uburyo bwo Kuvanga Igikorwa",
    "action-plan-combine-prompt-desc":
      "Uburyo bwo kuvanga igikorwa buyobora LLM mu kuvanga imigambi y'ibikorwa itandukanye yakorewe ku bisohoka bitandukanye bya LLM.",
    "action-plan-combine-prompt-placeholder":
      "Injizamo uburyo bwo kuvanga igikorwa...",
    "action-steps-combine-prompt": "Uburyo bwo Kuvanga Intambwe z'Ibikorwa",
    "action-steps-combine-prompt-desc":
      "Uburyo bwo kuvanga intambwe z'ibikorwa buyobora LLM mu kuvanga intambwe z'ibikorwa ziva ku bisohoka bitandukanye bya LLM.",
    "action-steps-combine-prompt-placeholder":
      "Injizamo uburyo bwo kuvanga intambwe z'ibikorwa...",
    "final-steps-combine-prompt": "Uburyo bwo Kuvanga Intambwe za Nyuma",
    "final-steps-combine-prompt-desc":
      "Uburyo bwo kuvanga intambwe za nyuma buyobora LLM mu kuvanga inyandiko ya nyuma hashingiwe ku bisohoka bitandukanye bya LLM.",
    "final-steps-combine-prompt-placeholder":
      "Injizamo uburyo bwo kuvanga intambwe za nyuma...",
  },

  chatboxdnd: {
    title: "Ongeraho dosiye",
    description:
      "Rekura dosiye yawe hano kugira ngo uyongere kuri ubu butumwa. Ntizabikwa mu mwanya w'akazi nk'isoko ihoraho.",
    "file-prefix": "Dosiye:",
    "attachment-tooltip":
      "Iyi dosiye izomekwa ku butumwa bwawe. Ntizabikwa mu mwanya w'akazi nk'isoko ihoraho.",
  },
  errors: {
    "thread-creation-failed":
      "Ntabwo byashobotse gukora thread nshya. Nyamuneka ongera ugerageze.",
  },
  rexor: {
    "register-project": "Kwandika umushinga wa Rexor",
    "project-id": "ID y'umushinga",
    "resource-id": "ID y'ibikoresho",
    "activity-id": "ID y'igikorwa",
    register: "Kwandika umushinga",
    registering: "kwandika ...",
    "not-active": "Uyu mwanya nturakora kugira ngo wandikwe",
    account: {
      title: "Kwinjira muri Rexor",
      username: "Izina ryo kwinjira",
      password: "Ijambo ryibanga",
      "no-token": "Nta token yakiriwe muri handleLoginSuccess",
      logout: "Gusohoka",
      "no-user": "Nyamuneka injira mbere",
      connected: "Wahurijwe na Rexor",
      "not-connected": "Ntabwo wahurijwe",
      "change-account": "Guhindura konti",
      "session-expired": "Igihe cyarangiye. Nyamuneka wongere winjire.",
    },
    "hide-time-transaction": "Hisha ifishi y'igihe",
    "show-time-transaction": "Erekana ifishi y'igihe",
    "time-transaction-title": "Ongeraho igikorwa cy'igihe",
    "registration-date": "Itariki yo kwandikwa",
    description: "Ibisobanuro",
    "description-internal": "Ibisobanuro by'imbere",
    "invoice-text": "Inyandiko y'inyemezabuguzi",
    "hours-worked": "Amasaha yakoze",
    "invoiced-hours": "Amasaha yishyuwe",
    invoiceable: "Bishobora kwishyurwa",
    "sending-time-transaction": "Kohereza igikorwa cy'igihe...",
    "save-time-transaction": "Bika igikorwa cy'igihe",
    "project-not-register": "Umushinga ugomba kwandikwa mbere.",
    "time-transaction-error": "Ntibishoboye kwandika igikorwa cy'igihe",
  },
  options: {
    yes: "Yego",
    no: "Oya",
  },
};

export default TRANSLATIONS;
